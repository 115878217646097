import React, { createContext, useContext, useReducer } from "react";

let MapDocketContext = createContext();

export function useMapDocketState() {
    return useContext(MapDocketContext);
}

const mapDocketStateReducer = (state, action) => {
    switch (action.type) {
        case "SELECT_DOCKET": {
            return {...state, selectedDocketId: action.selectedDocket.docketId, selectedDocket: action.selectedDocket};
        }
        default:
            return state;
    }
};

const initialState = {
    selectedDocketId: null,
    selectedDocket: null
};

export function MapDocketStateProvider({children}) {
let mapDocketState = useReducer(mapDocketStateReducer, initialState);

return <MapDocketContext.Provider value={mapDocketState}>{children}</MapDocketContext.Provider>
}