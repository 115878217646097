import React, { useEffect, useState } from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useAppState } from "../contexts/AppContext";
import { BASEAPI } from "../contexts/constants";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";
import { addOrEditEnum } from "./Enums";
import { handleErrors, isEmpty } from "./Helpers";
import "./user.css";

export default function UserForm({user, addOrEdit, userUpdated, updateCancelled}) {
    let [userId, setUserId] = useState(user ? user.userId : null);
    let [userName, setUserName] = useState(user ? user.userName : null);
    let [userDepots, setUserDepots] = useState(user ? user.depots : []);
    let [password, setPassword] = useState();
    let [passwordConfirm, setPasswordConfirm] = useState();

    let [loading, setLoading] = useState(false);
    let [depots, setDepots] = useState();
    let [appState, dispatch] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    useEffect(() => {
        fetchDepots();
    }, []);

    function buildUserForServer() {
        var user = {
            userName: userName,
            depotIds: userDepots.map(x => x.depotId)
        }

        if (addOrEdit == addOrEditEnum.Add) {
            user.password = password;
        }

        if (addOrEdit == addOrEditEnum.Edit) {
            user.userId = userId;
        }

        return user;
    }

    function buildUserForClient(userId) {
        var user = {
            userId: userId,
            userName: userName,
            depots: userDepots,
        }

        return user;
    }

    function fetchDepots() {
        setLoading(true);
        fetch(BASEAPI + "/api/Depot/GetAllFromAnySeason", {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setDepots(result);
        })
        .catch(function(error){ 
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Depots for Season", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    function update() {
        if (addOrEdit == addOrEditEnum.Add) {
            //send to server to add new user
            //on success call userUpdated
            var user = buildUserForServer();
            fetch(BASEAPI + "/api/User/AddUser", {
                method: "POST",
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    userUpdated(buildUserForClient(result.userId));
                }
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding User", errorMessage:"Server Error: " + error.message});
            })

        } else {
            //send to server to update user
            //on success call userUpdated
            var user = buildUserForServer();
            fetch(BASEAPI + "/api/User/UpdateUser", {
                method: "POST",
                body: JSON.stringify(user),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    userUpdated(buildUserForClient(userId));
                }
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating User", errorMessage:"Server Error: " + error.message});
            })
        }
    }

    function updateDepots(depotId, checked) {
        if (checked) {
            //find depot and add to user.depots
            var newDepot = depots.find(x => x.depotId == depotId);
            if (newDepot) {
                var updatedDepots = userDepots.concat(newDepot);
                setUserDepots(updatedDepots);
            }
        } else {
            //remove depot from user.depots
            var updatedDepots = userDepots.filter(x => x.depotId !== depotId);
            setUserDepots(updatedDepots);
        }
    }

    function isValid() {
        if (isEmpty(userName))
            return false;
        
        if (addOrEdit == addOrEditEnum.Add) {
            if (isEmpty(password) || isEmpty(passwordConfirm))
                return false;
            
            if (password != passwordConfirm)
                return false;
        }

        return true;
    }

    return (
        <div>
            <h3>{addOrEdit == addOrEditEnum.Add ? 'Add User' : 'Edit User'}</h3>

            <div className="formGroup">
                    <div className="groupTitle">{addOrEdit == addOrEditEnum.Add ? 'Add' : 'Edit'} Contact</div>

                    <FormGroup row>
                        <Label column sm="2" for="username"> Username:</Label>
                        <Col sm="10">
                            <Input type="text" id="username" value={userName} onChange={e => setUserName(e.target.value)} invalid={isEmpty(userName)} />
                            <FormFeedback valid={!isEmpty(userName)}>Please Enter A User Name</FormFeedback>
                        </Col>
                    </FormGroup>
                    {addOrEdit == addOrEditEnum.Add ?
                    <>
                        <FormGroup row>
                            <Label column sm="2" for="password"> Password:</Label>
                            <Col sm="10">
                                <Input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} invalid={isEmpty(password)} />
                                <FormFeedback valid={!isEmpty(password)}>Please Enter A Password</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label column sm="2" for="passwordConfirm"> Confirm Password:</Label>
                            <Col sm="10">
                                <Input type="password" id="passwordConfirm" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} invalid={isEmpty(passwordConfirm) || (password != passwordConfirm)} />
                                <FormFeedback valid={!isEmpty(password) && (password == passwordConfirm)}>Please Confirm Password and make sure it matches</FormFeedback>
                            </Col>
                        </FormGroup>
                    </> : null}
            </div>

            {loading && <Spinner color="primary" />}
            {depots && appState.deliverySeasons &&
                <div className="formGroup">
                    <div className="groupTitle">Depots</div>
                {appState.deliverySeasons.sort((a, b) => a.name < b.name ? 1: -1).map((season, index) => {
                    var seasonDepots = depots.filter(x => x.deliverySeasonId == season.deliverySeasonId);
                    return ( 
                    <>
                    {seasonDepots.length > 0 ?
                        <div>
                            <div className="formGroup">
                                <div className="seasonTitle">{season.name}</div>
                                <div className="depotContainer">
                                    {seasonDepots.map((depot, index) => {
                                        var userDepot = userDepots.find(x => x.depotId == depot.depotId);
                                        var checked = userDepot != null;
                                        return (<FormGroup check key={index}>
                                                            <Input type="checkbox" id={'depotChecked' + index} defaultChecked={checked} value={checked} onChange={e => updateDepots(depot.depotId, !checked)} />{' '}
                                                            <Label check >{depot.name}</Label>
                                                        </FormGroup>)
                                    }) }
                                </div>
                            </div>
                        </div> 
                        : <></>
                    } 
                    </>) 
                })}
                </div>
            }

            <div>
                <Button color="primary" disabled={!isValid()} onClick={() => update()}>Save User</Button>
                <Button onClick={() => updateCancelled()}>Cancel</Button>
            </div>
        </div>
    )
}