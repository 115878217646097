import React, { createContext, useContext, useReducer } from "react";
import { DEPOTS } from "./constants";

let DepotContext = createContext();

export function useDepotState() {
    return useContext(DepotContext);
}

const depotStateReducer = (state, action) => {
    switch (action.type) {
        case "INIT": {
            return {...state, depots: action.depots};
        }
        case "ADD_DEPOT": {
            return { ...state, depots: state.depots.concat(action.depot)};
        }
        case "ADD_DEPOT_ADDRESS": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.addressId = action.addressId;
            depot.address = action.address;
            return {...state, state}
        }
        case "UPDATE_DEPOT_ADDRESS": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.address = action.address;
            return {...state, state}
        }
        case "ADD_TRUCKTYPE": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            if (depot.truckTypes && depot.truckTypes.length > 0) {
                depot.truckTypes = depot.truckTypes.concat(action.truckType);
            }
            else {
                depot.truckTypes = [action.truckType];
            }
            return { ...state, state};
        }
        case "REMOVE_TRUCKTYPE": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.truckTypes = depot.truckTypes.filter(x => x.truckTypeId !== action.truckTypeId);
            return { ...state, state};
        }
        case "TOGGLE_TRUCKTYPE_EDITING": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            var truckType = depot.truckTypes.find(x => x.truckTypeId === action.truckTypeId);
            truckType.isEditing = !truckType.isEditing;
            return { ...state, state};
        }
        case "UPDATE_TRUCKTYPE": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            var truckType = depot.truckTypes.find(x => x.truckTypeId === action.truckTypeId);
            truckType.description = action.truckType.description;
            truckType.quantity= action.truckType.quantity;
            truckType.maxBoxCount = action.truckType.maxBoxCount;
            truckType.maxWeight = action.truckType.maxWeight;
            return { ...state, state};
        }
        case "ADD_DELIVERYDATE":{
            var depot = state.depots.find(x => x.depotId === action.depotId);
            if (depot.deliveryDates && depot.deliveryDates.length > 0) {
            //var nextId = getMaxDDId(depot.truckTypes) + 1;
            //depot.deliveryDates = depot.deliveryDates.concat({deliveryDateId: nextId, depotId: action.depotId, date: action.date, isMorning: null, deliverySeasonId: 2});
                depot.deliveryDates = depot.deliveryDates.concat(action.deliveryDate);
                depot.deliveryDates = depot.deliveryDates.sort((a,b) => {
                    var sameDate = a.date == b.date;

                    if (sameDate) {
                        if (a.isMorning === b.isMorning)
                            return 0;
                        else if (a.isMorning)
                            return -1;
                        else
                            return 1;
                    }

                    if (a.date < b.date) {
                        return -1
                    } else {
                        return 1;
                    }
                })
            }
            else {
                //depot.deliveryDates = [{deliveryDateId: 1, depotId: action.depotId, date: action.date, isMorning: null, deliverySeasonId: 2}];
                depot.deliveryDates = [action.deliveryDate];
            }
            return { ...state, state};
        }
        case "ADD_POSTCODES_AND_SUBURBS": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            var postCodeIds = action.postCodes.reduce((result, value) => {
                result.push(value.postCodeId);
                return result;
            }, []);
            if (depot.postCodeIds && depot.postCodeIds.length > 0) {
                depot.postCodeIds = depot.postCodeIds.concat(postCodeIds);
            }
            else {
                depot.postCodeIds = postCodeIds;
            }
            if (depot.postCodes && depot.postCodes.length > 0) {
                depot.postCodes = depot.postCodes.concat(action.postCodes);
            } else {
                depot.postCodes = action.postCodes;
            }
            var suburbIds = action.suburbs.reduce((result, value) => {
                result.push(value.suburbId);
                return result;
            }, []);
            if (depot.suburbIds && depot.suburbIds.length> 0) {
                depot.suburbIds = depot.suburbIds.concat(suburbIds);
            }
            else {
                depot.suburbIds = suburbIds;
            }
            if (depot.suburbs && depot.suburbs.length > 0) {
                depot.suburbs = depot.suburbs.concat(action.suburbs);
            } else {
                depot.suburbs = action.suburbs;
            }
            return { ...state, state};
        }
        case "REMOVE_DELIVERYDATE": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.deliveryDates = depot.deliveryDates.filter(x => x.deliveryDateId !== action.deliveryDateId);
            return {...state, state};
        }
        case "REMOVE_POSTCODE": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.postCodes = depot.postCodes.filter(x => x.postCodeId !== action.postCodeId);
            depot.postCodeIds = depot.postCodeIds.filter(x => x !== action.postCodeId);
            return {...state, state};
        }
        case "REMOVE_SUBURB": {
            var depot = state.depots.find(x => x.depotId === action.depotId);
            depot.suburbs = depot.suburbs.filter(x => x.suburbId !== action.suburbId);
            depot.suburbIds = depot.suburbIds.filter(x => x !== action.suburbId);
            return {...state, state};
        }
        case "REMOVE_DEPOT": {
            var newDepots = state.depots.filter(x => x.depotId !== action.depotId);
            return {...state, depots: newDepots};
        }
        default:
            return state;
    }
};

const initialState = {
    depots: [],
};

export function DepotStateProvider({children}) {
    let depotState = useReducer(depotStateReducer, initialState);

    return <DepotContext.Provider value={depotState}>{children}</DepotContext.Provider>
}