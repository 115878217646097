import React from "react";
import { Label } from "reactstrap";
import { useAppState } from "../contexts/AppContext";
import { useDepotState } from "../contexts/DepotContext";
import { useSecondPhaseFileState } from "../contexts/SecondPhaseFileContext";
import { depotSort } from "./Helpers";

export default function DepotSelector() {
    let [appState, appDispatch] = useAppState();
    let [depotState, depotDispatch] = useDepotState();
    let [secondPhaseFileState, secondPhaseFileDispatch] = useSecondPhaseFileState();

    function selectDepot(event) {
        console.log("select depot id: " + event.target.value)
        appDispatch({type: 'SELECT_DEPOT', depotId: event.target.value})
        secondPhaseFileDispatch({type: 'CLEAR_RUN'})
      }

    return (
        <div>
            {depotState.depots &&
                <Label>Current Depot: 
                    <select name="depots" onChange={selectDepot} value={appState.selectedDepotId}>
                        <option key="-1" value={-1}>Please Select...</option>
                        {depotState.depots.sort(depotSort).map((depot, index) => {
                            return (
                                <option key={index} value={depot.depotId}>{depot.name}</option>
                            );
                        })
                        }
                    </select>
                </Label>
            }
        </div>
    )
}
