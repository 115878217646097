import React, { useState } from "react"
import { FaMapMarkerAlt } from "react-icons/fa";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { handleErrors } from "./Helpers";

export default function AddressEdit({address, updatedAddress}) {
    let [addressId, setAddressId] = useState(address ? address.addressId : null);
    let [addressLine1, setAddressLine1] = useState(address ? address.addressLine1 : null);
    let [suburb, setSuburb] = useState(address ? address.city : null);
    let [state, setState] = useState(address ? address.state : null);
    let [postCode, setPostCode] = useState(address ? address.postCode : null);
    let [latitude, setLatitude] = useState(address ? address.latitude : null);
    let [longitude, setLongitude] = useState(address ? address.longitude : null);

    let [serverErrorState, dispatchServerError] = useServerErrorState();

    function geocodeAddress() {
        fetch(BASEAPI + "/api/address/GeocodeAddress/" + addressId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setLatitude(result.latitude);
            setLongitude(result.longitude);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Address", errorMessage:"Server Error: " + error.message});
        })
    }

    function updateAddress() {
        console.log("updating address");

        fetch(BASEAPI + "/api/Address", {
            method: "PUT",
            body: JSON.stringify({
                addressId: addressId,
                addressLine1: addressLine1,
                suburb: suburb,
                state: state,
                postCode: postCode,
                latitude: latitude,
                longitude: longitude
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            updatedAddress(result)
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Address", errorMessage:"Server Error: " + error.message});
        })
    }


    return (
        <div>
        <FormGroup as={Row}>
                <Label column sm="2" for="addressLine1">Street</Label>
                <Col sm="10">
                    <input type="text" id="addressLine1" value={addressLine1} onChange={e => setAddressLine1(e.target.value)}/>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="city">City</Label>
                <Col sm="10">
                    <input type="text" id="city" value={suburb} onChange={e => setSuburb(e.target.value)}/>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="state">State</Label>
                <Col sm="10">
                    <select id="state" value={state} onChange={e => setState(e.target.value)}>
                        <option>Please Select...</option>
                        <option value="NSW">New South Wales</option>
                        <option value="VIC">Victoria</option>
                        <option value="QLD">Queensland</option>
                        <option value="SA">South Australia</option>
                        <option value="WA">Western Australia</option>
                        <option value="TAS">Tasmania</option>
                        <option value="ACT">ACT</option>
                        <option value="NT">Northern Territory</option>
                    </select>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="postCode">Post Code</Label>
                <Col sm="10">
                    <input type="text" id="postCode" value={postCode} onChange={e => setPostCode(e.target.value)} />
                </Col>
            </FormGroup>
            {addressId > 0 &&
                <div>
                    <div><Button size="sm" color="info" onClick={geocodeAddress}><FaMapMarkerAlt /> Geocode Address</Button></div>
                    <FormGroup as={Row}>
                        <Label column sm="2" for="latitude">Latitude</Label>
                        <Col sm="10">
                            <input type="text" id="latitude" value={latitude} onChange={e => setLatitude(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Label column sm="2" for="longitude">Longitude</Label>
                        <Col sm="10">
                            <input type="text" id="longitude" value={longitude} onChange={e => setLongitude(e.target.value)} />
                        </Col>
                    </FormGroup>
                </div>
            }
            <Button color="success" onClick={updateAddress}>Update Address</Button>
            </div>
    )
}