import React from "react"
import DepotSelector from "./DepotSelector"
import DeliveryDateCalendar from "./DeliveryDateCalendar"
import { useAppState } from "../contexts/AppContext"

export default function Manage() {
    let [appState, appDispatch] = useAppState();

    let depotId = parseInt(appState.selectedDepotId);

    return (
        <div>
            <DepotSelector />
            {appState.selectedDepotId && 
                <DeliveryDateCalendar depotId={depotId} />
            }
        </div>
    )
}