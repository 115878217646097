import React from "react";
import { Button } from "reactstrap";
import { FaTruck } from "react-icons/fa";
import { MdEdit, MdDelete } from "react-icons/md";
import "./TruckTypeTile.css";
import { useDepotState } from "../contexts/DepotContext";
import TruckTypeTileEditPanel from "./TruckTypeTileEditPanel";
import TruckTypeTileInfoPanel from "./TruckTypeTileInfoPanel";
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function TruckTypeTile({truckType}) {
    let [state, dispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    function removeTruckType()
    {
        fetch(BASEAPI + "/api/Depot/" + truckType.depotId + "/TruckType/" + truckType.truckTypeId, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }            
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                dispatch({type: "REMOVE_TRUCKTYPE", depotId: truckType.depotId, truckTypeId: truckType.truckTypeId})
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Removing TruckType", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <div className="truckTypeTile">
            <div>
                <div><FaTruck /></div>
                <div className="topButtons">
                    {!truckType.isEditing && <Button size="sm" color="warning" onClick={() => dispatch({type: "TOGGLE_TRUCKTYPE_EDITING", depotId: truckType.depotId, truckTypeId: truckType.truckTypeId})}><MdEdit /></Button>}
                    <Button size="sm" color="danger" onClick={removeTruckType}><MdDelete /></Button>
                </div>
            </div>
            {truckType.isEditing ? 
                <TruckTypeTileEditPanel truckType={truckType} /> :
                <TruckTypeTileInfoPanel truckType={truckType} />}
        </div>
    )
}