export var taskStatusEnum = {
    Queued: 1,
    Running: 2,
    Completed: 3,
    Failed: 4
}

export function taskStatusLabel(taskStatus) {
    switch (taskStatus) {
        case 1:
            return "Queued";
        case 2:
            return "Running";
        case 3:
            return "Completed";
        case 4:
            return "Failed";
    }
}

export var addOrEditEnum = {
    Add: 1,
    Edit: 2
}