import React, { useState, useEffect } from "react";
import RunTile from "./RunTile";
import "./runs.css";
import { Alert, Button } from "reactstrap";
import DisplayRoutesMap from "./DisplayRoutesMap";
import { useAppState } from "../contexts/AppContext";
import { useDepotState } from "../contexts/DepotContext";
import { useMapDocketState } from "../contexts/MapDocketContext";
import ActiveDocket from "./ActiveDocket";
import { FaMap } from "react-icons/fa";
import RoutesMap from "./maps/RoutesMap";
import GoogleMap from "./maps/GoogleMap";

export default function RunsList(params) {
    //let runs = params.runs;
    let [runs, setRuns] = useState();
    let importFileId = params.importFileId;
    let [appState, appDispatch] = useAppState();
    let [depotState, depotDispatch] = useDepotState();
    let [showMap, setShowMap] = useState(false);

    let [mapDocketState, mapDocketDispatch] = useMapDocketState();

    let depotId = parseInt(appState.selectedDepotId);

    let depot = depotState.depots.find(x => x.depotId === depotId);
    let hasTruckTypes = depot.truckTypes && depot.truckTypes.length > 0;

    function toggleMap() {
        setShowMap(!showMap);
    }

    useEffect(() => { 
        setRuns(params.runs);
        setShowMap(false);
    }, [params.runs])

    return (
        <div>
            {!hasTruckTypes && <div>
                    <Alert color="danger">No Truck Types configured for Depot.  Please go to 'Config' and add a truck type for this depot</Alert>
                </div> }
            <div>
                <div><Button color="info" onClick={toggleMap}>{showMap ? "Hide" : "Show"} Map <FaMap /></Button></div>
                {showMap && 
                    // <DisplayRoutesMap runs={runs.sort((a,b) => a.runNumber - b.runNumber)} depotId={depotId} />
                    //<RoutesMap runs={runs.sort((a,b) => a.runNumber - b.runNumber)} depotId={depotId} />
                    <GoogleMap runs={runs.sort((a,b) => a.runNumber - b.runNumber)} depotId={depotId} />
                }
                {showMap && mapDocketState.selectedDocketId &&
                    <ActiveDocket docket={mapDocketState.selectedDocket} runs={runs}></ActiveDocket>}
            </div>
            <div className="runsGrid">
                {runs && runs.sort((a,b) => a.runNumber - b.runNumber).map((run, index) => {
                    var truckType = depot.truckTypes[0];

                    return <RunTile key={index} run={run} truckType={truckType} runIndex={index} importFileId={importFileId} />
                })}
            </div>
        </div>
    )
}