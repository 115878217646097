import React from "react";
import { MdEdit } from "react-icons/md";
import { FaBoxes, FaTrash } from "react-icons/fa";
import { Button } from "reactstrap";
import "./DepotTile.css";
import { useHistory } from "react-router-dom";

export default function DepotTile({depot, removeDepot}) {
    let history = useHistory();
    let truckCount = 0;
    let totalBoxCount = 0;
    let totalWeight = 0;
    let totalVolume = 0;

    var className = depot.isMDA ? "mdaDepotTile" : "depotTile";

    if (depot.truckTypes) {
        truckCount = depot.truckTypes.reduce(function (cnt,t) { return cnt + t.quantity; }, 0);
        totalBoxCount = depot.truckTypes.reduce((cnt, t) => { return cnt + (t.quantity * t.maxBoxCount);}, 0);
        totalWeight = depot.truckTypes.reduce((cnt, t) => {return cnt +(t.quantity * t.maxWeight);}, 0);

        if (depot.isMDA) {
            totalVolume = depot.truckTypes.reduce((cnt, t) => {return cnt + (t.quantity * t.maxVolume);}, 0);
        }
    }

    function editClick() {
        history.push("/depot/" + depot.depotId);
    }

    function onRemoveDepot(depotId) {
        if (window.confirm("Are you sure you wish to delete depot " + depot.name + "?")) {
            removeDepot(depotId);
        }
    }

    return (
        <div className={className}>
            <div className="depotName">{depot.name}</div>
            <div>Total Vehicles: {truckCount}</div>
            <div>Total Capacity: {totalBoxCount} <FaBoxes /> - {totalWeight} kg {depot.isMDA ? '- ' + totalVolume + 'm' + String.fromCharCode(179) : null}</div>
            <div># of Post Codes {depot.postCodeIds != null ? depot.postCodeIds.length: 0}</div>
            <div># of Suburbs {depot.suburbIds != null ? depot.suburbIds.length: 0} </div>
            <div># of Delivery Sessions {depot.deliveryDates != null ? depot.deliveryDates.length : 0}</div>

            <div className="depotEditBtn">
                <Button className="btn" size="sm" color="warning" onClick={editClick}>Edit <MdEdit></MdEdit></Button>
                <Button className="btn" size="sm" color="danger" onClick={() => onRemoveDepot(depot.depotId)}>Remove <FaTrash /></Button>
            </div>
        </div>
    )
}