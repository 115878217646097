import React, { useEffect, useState } from "react";
import { BASEAPI } from "../contexts/constants";
import { useParams } from 'react-router-dom';
import RunsList from "./RunsList";
import { Button, Spinner, Alert } from "reactstrap";
import { FaFileExcel, FaTruck, FaRegCalendarAlt } from "react-icons/fa";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import "./ImportDocket.css";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { format } from "date-fns";
import { useAppState } from "../contexts/AppContext";
import { useUserState } from "../contexts/UserContext";

export default function ImportFileRuns() {
    let { importFileId } = useParams();
    let [runs, setRuns] = useState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [processing, setProcessing] = useState(false);
    let [dateState, dateDispatch] = useDeliveryDateState();
    let [appState, appDispatch] = useAppState();
    let [userState, userDispatch] = useUserState();
    let [newDateId, setNewDateId] = useState();

    let delDates = dateState.deliveryDates.filter(x => x.depotId === parseInt(appState.selectedDepotId));

    function exportToExcel() {
        setProcessing(true);
        fetch(BASEAPI + "/api/run/ExportExcel/" + importFileId,{
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "ExcelExport.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Generating Excel Document", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    function addTruck() {
        setProcessing(true);

        fetch(BASEAPI + "/api/run/AddRun/" + importFileId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            var updatedRuns = runs.concat(result).sort((a, b) => {
                if (a.runNumber < b.runNumber)
                    return -1;
                else if (a.runNumber == b.runNumber)
                    return 0;
                else
                    return 1;
            });
            setRuns(updatedRuns);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Run", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    function assignDateToAll() {
        setProcessing(true);

        var runIds = runs.reduce((result, value) => {
            result.push(value.runId);
            return result;
        }, []);

        fetch(BASEAPI + "/api/DeliveryDate/AssignAllRunsToDate", {
            method: "POST",
            body: JSON.stringify({
                deliveryDateId: parseInt(newDateId),
                runIds: runIds
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            runs.forEach(run => {
                run.deliveryDateId = result.deliveryDateId
            });
            dateDispatch({type: 'ADD_RUNS_TO_DATE', deliveryDateId: result.deliveryDateId, runs: runs});
            setRuns(runs);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Assigning Delivery Date to Runs", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    useEffect(() => {
        setProcessing(true);
        fetch(BASEAPI + "/api/run/RunsForFile/" + importFileId, {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRuns(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Runs For File", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }, [])

    return (
        <div>
            <div className="btnGroup">
                <Button color="primary" onClick={addTruck}>Add Run <FaTruck /></Button>
                <Button color="warning" onClick={exportToExcel}>Export Excel <FaFileExcel /></Button>
            </div>
            <div>
                {delDates.length > 0 &&
                    <div>
                        <select value={newDateId} onChange={(e) => setNewDateId(e.target.value)}>
                            <option key="-1" value={null}>Please Select...</option>
                            {delDates.map((dd, index) => {
                                var formattedDate = format(new Date(dd.date), "dd/MM/yyyy");
                                if (dd.isMorning != null)
                                {
                                    formattedDate += ' ' + (dd.isMorning ? 'AM' : 'PM');
                                }
                                return (
                                    <option index={index} value={dd.deliveryDateId}>{formattedDate}</option>
                                )
                            })}
                        </select>
                        <Button color="success" onClick={assignDateToAll}>Assign All Runs To Date <FaRegCalendarAlt /></Button>
                    </div>
                }
                {delDates.length == 0 &&
                <Alert color="warning">Please setup delivery dates in Depot Config to Assign Runs to Delivery Date</Alert>}
            </div>
            {processing && <Spinner />}
            {runs && 
            <div>
                <RunsList runs={runs} importFileId={importFileId} />
            </div>}
        </div>
    )
}