import React, {createContext, useContext, useReducer } from "react";

let SecondPhaseFileContext = createContext();

export function useSecondPhaseFileState() {
    return useContext(SecondPhaseFileContext);
}

const secondPhaseFileStateReducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_FILE":
            return {...state, selectedImportFileId: action.selectedImportFileId, secondPhaseFile: action.secondPhaseFile };
        case "UPDATE_DELIVERYDATES":
            state.secondPhaseFile.deliveryDates = action.deliveryDates;
            return {...state, state};
        case "UPDATE_RUN":
            //find date, update run
            var dDate = state.secondPhaseFile.deliveryDates.find(x => x.deliveryDateId === action.run.deliveryDateId);
            var indexOf = dDate.runs.findIndex(x => x.runId === action.run.runId);
            dDate.runs[indexOf] = action.run;
            return {...state, state};
        case "CLEAR_RUN":
            state.secondPhaseFile = null;
            state.selectedImportFileId = null;
            return {...state, state};
        default:
            return state;
    }
}

const initialState = {
    secondPhaseFile: null,
    selectedImportFileId: null
};

export function SecondPhaseFileStateProvider({children}) {
    let secondPhaseFileState = useReducer(secondPhaseFileStateReducer, initialState);

    return <SecondPhaseFileContext.Provider value={secondPhaseFileState}>{children}</SecondPhaseFileContext.Provider>
}