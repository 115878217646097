import React from "react";
import { format } from "date-fns";

export default function RouteProgress({progressEvents}) {
    return (
        <div className="formGroup">
            <div className="groupTitle">Routing Progress</div>
            {progressEvents && progressEvents.map((progressEvent, index) => {
                let formattedTime = format(new Date(progressEvent.dateTime), "dd/MM/yyyy hh:mm:ss")
                return (
                <div>[{formattedTime}] - {progressEvent.message}</div>
                )
            })}
        </div>
    )
}