import React, { createContext, useContext, useReducer } from "react";

let UserContext = createContext();
let devUsers = ["graemefinn"]

export function useUserState() {
    return useContext(UserContext);
}

const userStateReducer = (state, action) => {
    switch (action.type) {
        case "INIT": {
            sessionStorage.setItem("user", JSON.stringify(action));
            return {...state, user: action.user, userId: action.userId, isDevUser: devUsers.includes(action.user), canRoute: action.canRoute, externalLogin: action.externalLogin, userDepots: action.userDepots };
        }
        case "SET_TOKEN":
            console.log("setting state to token: " + action.token)
            sessionStorage.setItem("token", action.token);
            return {...state, token: action.token};
        case "CLEAR":
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            return {...state, token: null, user: null}
        default:
            return state;
    }
};

const initialState = {
    user: null,
    userId: null,
    isDevUser: false,
    canRoute: false,
    token: null,
    userDepots: [],
    externalLogin: false
};

export function UserStateProvider({children}) {
let userState = useReducer(userStateReducer, initialState);

return <UserContext.Provider value={userState}>{children}</UserContext.Provider>
}