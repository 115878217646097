import React, { useState, useEffect } from "react";
import { useDepotState } from "../contexts/DepotContext";
import AddDepotTile from "./AddDepotTile";
import DepotTile from "./DepotTile";
import { Button, Collapse, Card, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import "./Config.css"
import { depotSort, handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function Config() {
    let [{depots}, dispatchDepot] = useDepotState();
    let [showPostCode, setShowPostCode] = useState(false);
    let [showPostCodeImport, setShowPostCodeImport] = useState(false);
    let [counts, setCounts] = useState(null);
    let [postCodes, setPostCodes] = useState(null);
    let [errorState, dispatchServerError] = useServerErrorState();
    let [userState, dispatchUserState] = useUserState();

    function submitPostCodeFile() {
        var fileInput = document.querySelector('#inputFile');

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);

        fetch(BASEAPI + "/api/config/ImportPostCodes", {
            method: "POST",
            body: data,
            headers: {
                  Authorization: 'Bearer ' + userState.token
                }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            setCounts(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Post Codes and Suburbs", errorMessage:"Server Error: " + error.message});
        })
    }

    function showImport() {
        setShowPostCodeImport(true);
    }

    function hideImport() {
        setShowPostCodeImport(false);
    }

    function togglePostCode() {
        setShowPostCode(!showPostCode);
    }

    function removeDepot(depotId) {
        fetch(BASEAPI + "/api/depot/" + depotId, {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
            }
        }).then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result.isDeleted) {
                dispatchDepot({type: "REMOVE_DEPOT", depotId: depotId});
            }
        }).catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Deleting Depot", errorMessage:"Server Error: " + error.message});
        })
    }

    function fetchPostCodes() {
        fetch(BASEAPI + "/api/Config/GetAllPostCodesAndSuburbs", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setPostCodes(result.postCodes);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Post Codes", errorMessage:"Server Error: " + error.message});
        })
    }

    useEffect(() => {
        fetchPostCodes()
    }, [])

    return (
        <div>
            <div>
                <h1>Depots</h1>
                <div className="grid-container">
                    {depots.sort(depotSort).map((depot, index) => {
                        return (
                            <DepotTile key={depot.depotId} depot={depot} removeDepot={removeDepot} />
                        );
                    })}
                    {!userState.externalLogin && <AddDepotTile />}
                </div>
            </div>
            {!userState.externalLogin &&
            <div>
                <h1>Post Codes and Suburbs</h1>
                
                <Button onClick={togglePostCode}>Show Post Code List</Button>

                <Collapse isOpen={showPostCode}>

                    {!showPostCodeImport && 
                <div>
                    <Button onClick={showImport}>Update Suburb List</Button>
                </div>
                }

                <Collapse isOpen={showPostCodeImport}>
                    <Card>
                        <div>
                            Import CSV file in format from <a href="https://www.matthewproctor.com/australian_postcodes">https://www.matthewproctor.com/australian_postcodes</a>
                        </div>
                        <input type="file" name="file" id="inputFile" />
                        <Button color="primary" onClick={submitPostCodeFile}>Import</Button>
                        {counts &&
                            <div>
                                <div>Successfully Imported File</div>
                                <div>
                                    Post Codes: {counts.postCodesAdded} Added {counts.postCodesUpdated} Updated
                                    Suburbs: {counts.suburbsAdded} Added {counts.suburbsUpdated} Updated
                                </div>
                                <Button color="secondary" onChange={hideImport}>Close</Button>
                            </div>
                        }
                    </Card>
                </Collapse>

                {!showPostCodeImport &&
                <div>
                    <div>
                        Post Codes
                    </div>
                    {!postCodes && 
                        <Spinner color="primary" />
                    }
                    {postCodes &&
                        <ListGroup>
                            {postCodes.map((postCode, i) => {
                                return (
                                <ListGroupItem>
                                    <div>{postCode.code}</div>
                                    <div className="suburb-grid">
                                        {postCode.suburbs && postCode.suburbs.map((suburb, j) => {
                                            return <div key={j}>{suburb.name}</div>
                                        })}
                                    </div>
                                </ListGroupItem>);
                            })}
                        </ListGroup>
                    }
                </div>
                }
                </Collapse>
            </div>
            }
        </div>
    );
}