import React, {createContext, useContext, useReducer } from "react";

let ServerErrorContext = createContext();

export function useServerErrorState() {
    return useContext(ServerErrorContext);
}

const serverErrorStateReducer = (state, action) => {
    switch (action.type) {
        case "SET_ERROR":
            return {...state, errorTitle: action.errorTitle, errorMessage: action.errorMessage};
        case "CLEAR_ERROR":
            return {...state, errorTitle: null, errorMessage: null}
        default:
            return state;
    }
}

const initialState = {
    errorTitle: null,
    errorMessage: null
};

export function ServerErrorStateProvider({children}) {
    let serverErrorState = useReducer(serverErrorStateReducer, initialState);

    return <ServerErrorContext.Provider value={serverErrorState}>{children}</ServerErrorContext.Provider>
}