import React, { useState } from "react";
import { Button, Collapse } from "reactstrap";
import { useParams, useHistory } from "react-router-dom";
import { useDepotState} from "../contexts/DepotContext";
import DepotAddressEdit from "./DepotAddressEdit";
import TruckTypeList from "./TruckTypeList";
import "./DepotEdit.css";
import EditDeliveryDateList from "./EditDeliveryDateList";
import DepotPostCodes from "./DepotPostCodes";
import DepotSuburbs from "./DepotSuburbs";

export default function DepotEdit() {
    let { depotId } = useParams();
    let [state, dispatch] = useDepotState();
    let { push } = useHistory();
    let [showSuburbs, setShowSuburbs] = useState(false);

    let depot = state.depots.find(x => x.depotId === parseInt(depotId));

    function toggleSuburbs() {
        setShowSuburbs(!showSuburbs);
    }

    return (
        <div>
            <div>
                <h2>Edit {depot.name} Depot Config <span className="returnBtn"><Button color="secondary" size="sm" onClick={() => push('/config')}>Return to List</Button></span></h2>
            </div>
            <div className="formGroup">
                <div className="groupTitle">Address</div>
                <div>
                    <DepotAddressEdit depotId={depot.depotId} />
                </div>
            </div>
            <div className="formGroup">
                <div className="groupTitle">Trucks</div>
                <div>
                    <TruckTypeList depot={depot} />
                </div>
            </div>
            <div className="formGroup">
                <div className="groupTitle">Post Codes</div>
                <DepotPostCodes depot={depot} />
            </div>
            <div className="formGroup">
                <div className="groupTitle">Suburbs</div>
                <Button color="primary" onClick={toggleSuburbs}>{showSuburbs ? "Hide" : "Show"}</Button>
                <Collapse isOpen={showSuburbs}>
                    <DepotSuburbs depot={depot} />
                </Collapse>
            </div>
            <div className="formGroup">
                <div className="groupTitle">Delivery Dates</div>
                <div>
                    <EditDeliveryDateList depot={depot} />
                </div>
            </div>
        </div>
    )
}