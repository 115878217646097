import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Button, Col, Container, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { handleErrors } from "./Helpers";

import "./failed.css";

import { useUserState } from "../contexts/UserContext";
import { useAppState } from "../contexts/AppContext";
import { useDepotState } from "../contexts/DepotContext";

var SortTypeEnum = {
    Distance: 1,
    Weight: 2,
    Volume: 3
}

export default function FailedRoute() {
    let { importFileId } = useParams();
    let [importFile, setImportFile] = useState();
    let [dockets, setDockets] = useState();
    let [averageDistances, setAverageDistances] = useState();
    let [exclusionStatuses, setExclusionStatus] = useState();
    let [sortByDistance, setSortByDistance] = useState(SortTypeEnum.Distance);
    let [errorState, dispatchServerError] = useServerErrorState();

    let [userState, dispatchUser] = useUserState();
    let [appState, dispatchAppState] = useAppState();
    let [depotState, dispatchDepotState] = useDepotState();

    var currentDepot = depotState.depots.find(d => d.depotId === parseInt(appState.selectedDepotId));

    function getDockets(docketIds) {
        var data = {
            docketIds: docketIds
        };

        fetch(BASEAPI + "/api/ImportDockets/GetDockets", 
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setDockets(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Dockets", errorMessage:"Server Error: " + error.message});
        })
    }

    function getAverageDistances() {

        fetch(BASEAPI + "/api/ImportDockets/GetAverageDistances/" + importFileId, 
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setAverageDistances(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Average Distances", errorMessage:"Server Error: " + error.message});
        })
    }

    function getExclusionStatuses(docketIds) {
        var data = {
            importFileId: parseInt(importFileId),
            docketIds: docketIds
        };

        fetch(BASEAPI + "/api/ImportDockets/GetExcludedStatus", 
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setExclusionStatus(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Dockets Exclusion Status", errorMessage:"Server Error: " + error.message});
        })
    }

    useEffect(() => {
        if (importFile) {
            getDockets(importFile.docketIds);
            getAverageDistances();
            getExclusionStatuses(importFile.docketIds);
        }
    }, [importFile])

    useEffect(() => {

        fetch(BASEAPI + "/api/ImportDockets/" + importFileId,{
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setImportFile(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Dockets for Import File", errorMessage:"Server Error: " + error.message});
        })

    }, [])

    function changeSort(event) {
        if (event.target.value == SortTypeEnum.Distance) {
            setSortByDistance(SortTypeEnum.Distance);
        } else if (event.target.value == SortTypeEnum.Weight) {
            setSortByDistance(SortTypeEnum.Weight);
        } else if (event.target.value == SortTypeEnum.Volume) {
            setSortByDistance(SortTypeEnum.Volume)
        }
    }

    function excludeDocket(docketId) {

        var data = {
            importFileId: parseInt(importFileId),
            docketId: docketId
        }

        fetch(BASEAPI + "/api/ImportDockets/ExcludeFromRouting",
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                setExclusionStatus(exclusionStatuses.map(x => {
                    if (x.docketId !== docketId) return x;
                    return {...x, isExcluded: true}
                }));
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Excluding Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    function unexclude(docketId) {
        var data = {
            importFileId: parseInt(importFileId),
            docketId: docketId
        }

        fetch(BASEAPI + "/api/ImportDockets/UnExcludeFromRouting",
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                setExclusionStatus(exclusionStatuses.map(x => {
                    if (x.docketId !== docketId) return x;
                    return {...x, isExcluded: false}
                }));
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Excluding Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <div>
            <h3>Failed Route</h3>
            <FormGroup onChange={changeSort}>Sort by 
                <FormGroup check>
                    <Label><Input type="radio" name="sortBy" value={SortTypeEnum.Distance} defaultChecked />Average Distance</Label>
                </FormGroup>
                <FormGroup check>
                    <Label><Input type="radio" name="sortBy" value={SortTypeEnum.Weight} />Order Weight</Label>
                </FormGroup>
                {currentDepot && currentDepot.isMDA &&
                <FormGroup check>
                    <Label><Input type="radio" name="sortBy" value={SortTypeEnum.Volume} />Order Volume</Label>
                </FormGroup>
                }
            </FormGroup>
            <Container>
                <Row className="headerRow">
                    <Col>Member Number</Col>
                    <Col>Order No</Col>
                    <Col>Address</Col>
                    <Col>Box Count</Col>
                    <Col>Order Weight</Col>
                    {currentDepot && currentDepot.isMDA && <Col>Order Volume</Col>}
                    <Col>Average Distance To Other Dockets (KM)</Col>
                    <Col></Col>
                </Row>
            
            {dockets && averageDistances ?
                    <div>{dockets.sort((a, b) => {
                        if (sortByDistance === SortTypeEnum.Distance) {
                            return averageDistances.find(x => x.docketId == a.docketId).averageDistance - averageDistances.find(x => x.docketId == b.docketId).averageDistance
                        } else if (sortByDistance === SortTypeEnum.Weight)
                        {
                            return a.orderWeight - b.orderWeight
                        } else {
                            return a.totalVolume - b.totalVolume
                        }
                    }).map((docket, index) => {
                        var exclusionStatus = exclusionStatuses ? exclusionStatuses.find(x => x.docketId == docket.docketId) : null;

                        return (
                        <Row key={index}>
                            <Col>{docket.memberNumber}</Col>
                            <Col>{docket.orderNumber}</Col>
                            <Col>{docket.address.addressLine1 + ', ' + docket.address.city + ' ' + docket.address.state + ' ' + docket.address.postCode}</Col>
                            <Col>{docket.boxCount}</Col>
                            <Col>{docket.orderWeight}</Col>
                            {currentDepot && currentDepot.isMDA && <Col>{docket.totalVolume}</Col>}
                            <Col>{averageDistances && <>{Math.round(averageDistances.find(x => x.docketId == docket.docketId).averageDistance /1000)}</>}</Col>
                            <Col>
                                {exclusionStatus && exclusionStatus.isExcluded && <Button onClick={() => unexclude(docket.docketId)}>Include Again</Button>}
                                {exclusionStatus && !exclusionStatus.isExcluded && <Button onClick={() => excludeDocket(docket.docketId)}>Exclude</Button>}
                            </Col>
                        </Row>
                        )
                    })}
                    </div>
            : <Spinner />} 
            </Container>
        </div>
    )

}