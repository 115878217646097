import React, { useState } from "react";
import { MdCompareArrows } from "react-icons/md";
import { Label, Container, Row, Col, Button, Alert } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import { handleErrors} from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useSecondPhaseFileState } from "../contexts/SecondPhaseFileContext";
import { format } from "date-fns";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { useUserState } from "../contexts/UserContext";

export default function ActiveDocket(params) {
    let docket = params.docket;
    let runs = params.runs;
    let [newRunId, setNewRunId] = useState();
    let [successMessage, setSuccessMessage] = useState();
    let [moving, setMoving] = useState(false);
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();
    let [deliveryDateState, dateDispatch] = useDeliveryDateState();
    let [userState, dispatchUser] = useUserState();

    function moveDocket() {
        setSuccessMessage(null);
        
        if (!newRunId || newRunId <= 0) {
            alert("Please select a run to move docket to");
            return;
        }

        setMoving(true);

        fetch(BASEAPI + "/api/Run/MoveDocket", {
            method: "POST",
            body: JSON.stringify({
                docketId: docket.docketId,
                newRunId: parseInt(newRunId)
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setSuccessMessage(docket.memberNumber + ' ' + docket.orderNumber + ' successfully moved to new run');
            if (secondPhaseFileState.secondPhaseFile) {
                dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.oldRun});
                dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.newRun});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setMoving(false);
        })
    }

    return (
        <div>
            <div className="formGroup">
                <div className="groupTitle">Selected Docket</div>
                <Container>
                <Row>
                    <Col><Label>Member No</Label><div>{docket.memberNumber}</div></Col>
                    <Col><Label>Order No</Label><div>{docket.orderNumber}</div></Col>
                </Row>
                <Row>
                    <Col><Label>Address</Label><div>{docket.address.addressLine1 + ', ' + docket.address.city + ' ' + docket.address.state + ' ' + docket.address.postCode}</div></Col>
                </Row>
                <Row>
                    <Col><Label>Box Count</Label><div>{docket.boxCount}</div></Col>
                    <Col><Label>Order Weight</Label><div>{docket.orderWeight}</div></Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                        <Label>New Run</Label>
                        <select onChange={(e) => setNewRunId(e.target.value)} value={newRunId}>
                            <option value="-1">Select New Run</option>
                            {runs.sort((a,b) => a.runNumber - b.runNumber).map((run, index) => {
                                var date = run.deliveryDateId ? deliveryDateState.deliveryDates.find(x => x.deliveryDateId  === run.deliveryDateId) : null;

                                return (
                                    <option key={index} value={run.runId}>{run.runNumber + (date ? " [" + format(new Date(date.date), "dd/MM/yyyy")  +"]" : "")}</option>
                                )
                            })}
                        </select>
                        </div>
                        <div>
                            <Button color="primary" onClick={moveDocket} disabled={moving}><MdCompareArrows />Move Docket</Button>
                            {successMessage && 
                            <Alert color="success">{successMessage}</Alert>}
                        </div>
                    </Col>
                </Row>
                </Container>
            </div>
        </div>
    )
}