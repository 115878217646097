import { useServerErrorState } from "../contexts/ServerErrorContext";
import {isUndefined, isString} from "lodash";

export function handleErrors(response) {
    if (!response.ok)
    {
        var error = response.json();
        
        return error.then(error => {throw Error(error.title)});
        
    }
    
    return response;
}

export function pad (str, max) {
    if (str) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    } else {
        return "-";
    }
  }

export function isEmpty(value) {
    return isUndefined(value) || value === null || (isString(value) && value.trim() === '') || value === false || (Array.isArray(value) && value.length === 0 );
}

export function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

export function depotSort(a,b) {
    return a.name.localeCompare(b.name);
}
