import React, { useState } from "react"
import { Form, Button, FormGroup, Label, Spinner, Row, Col, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import ImportDocketFileConfirmation from "./ImportDocketFileConfirmation";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function ImportDocketFile() {
    let { push } = useHistory();
    let [appState, appDispatch] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [depotState, depotDispatch] = useDepotState();
    let [errorState, dispatchServerError] = useServerErrorState();

    let [dockets, setDockets] = useState(null);
    let [file, setFile] = useState(null);

    let [postingFile, setPostingFile] = useState(false);

    var currentDepot = depotState.depots.find(d => d.depotId === parseInt(appState.selectedDepotId));

    function importFile(event) {
        setPostingFile(true);

        var fileInput = document.querySelector('#inputFile');

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('depotId', parseInt(appState.selectedDepotId));
        data.append('deliverySeasonId', parseInt(appState.selectedDeliverySeasonId))
        var uri = BASEAPI + (currentDepot.isMDA ? '/api/ImportDockets/ImportMDADocketFile' : '/api/ImportDockets');
        fetch(uri, {
            method: 'POST',
            body: data,
            headers: {
                  Authorization: 'Bearer ' + userState.token
                }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            setDockets(result.importedRows);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Uploading File", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setPostingFile(false);
        })

    }

    function fileChanged() {
        var fileInput = document.querySelector('#inputFile');
        //setFilename(fileInput.files.item(0).name);
        setFile(fileInput.files[0]);
    }

    return (
        <div>
            {!dockets && 
            <div>
                <div>
                    <span className="returnBtn"><Button color="secondary" size="sm" onClick={() => push('/route')}>Return to List</Button></span>
                </div>
                <div>
                    <h2>Import Docket File for {currentDepot && currentDepot.name} Depot</h2>
                </div>
                <div className="text-info">
                    Excel File needs header and the following fields: <br/>
                    {currentDepot.isMDA 
                        ? <pre>MEMBER#,ORDER#,STREET NAME,ADDR1,SUBURB,STATE,POSTCODE,DELV DATE,DELV RUN#,DELV TIME,SEQ#,TOTAL BOXES,TOTAL CUBES,TOTAL WEIGHT,SPECIAL INSTRS,DELIVERY AGENT COMMENT</pre> 
                        : <pre>MEMBER#,ORDER#,STREET NAME,ADDR1,SUBURB,STATE,POSTCODE,DELV DATE,DELV RUN#,DELV TIME,SEQ#,TOTAL BOXES,TOTAL WEIGHT,SPECIAL INSTRS,DELIVERY AGENT COMMENT</pre>}
                    {currentDepot.isMDA && <Alert color="warning">Note: MDA file has extra column <strong>Total Cubes</strong> straight before <strong>Total Weight</strong></Alert>}
                    System will validate dockets and return list to action before storing.
                </div>
                <div>
                    <Form>
                        <FormGroup>
                            <Label for="inputFile">Docket File</Label>
                            <Col>
                                <input type="file" name="file" id="inputFile" onChange={fileChanged}/>
                            </Col>
                        </FormGroup>
                        {(dockets == null || dockets.length === 0) &&
                        <FormGroup>
                            <Button color="primary" onClick={importFile} disabled={postingFile}>Import</Button> {postingFile && <Spinner />}
                        </FormGroup>
                        }
                    </Form>
                </div>
            </div>
            }
            {dockets && dockets.length > 0 && 
                        <ImportDocketFileConfirmation dockets={dockets} file={file} depotName={currentDepot.name} depotId={currentDepot.depotId}/>
            }
        </div>
    )
}