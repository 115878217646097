import React, { useState } from "react";
import { Container, Row, Col, Button, Card, CardTitle, CardText, Badge, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Input } from "reactstrap";
import "./ImportDocket.css";
import { useAppState } from "../contexts/AppContext";
import { BASEAPI } from "../contexts/constants";
import { useDepotState } from "../contexts/DepotContext";
import ImportDocketComplete from "./ImportDocketComplete";
import { useUserState } from "../contexts/UserContext";
import { FaExclamation, FaGlobe } from "react-icons/fa";
import { reduceEachLeadingCommentRange } from "typescript";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";

export default function ImportDocketFileConfirmation(params) {
    let [dockets, setDockets] = useState(params.dockets);
    let [file, setFile] = useState(params.file);
    let [depotName, setDepotName] = useState(params.depotName);
    let [depotId, setDepotId] = useState(params.depotId);
    let [appState, dispatchAppState] = useAppState();
    let [depotState, depotDispatch] = useDepotState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [savedDockets, setSavedDockets] = useState();
    let [importFileId, setImportFileId] = useState();
    let [userState, userDispatch] = useUserState();
    let [assignAllNoRunStatus, setAssignAllNoRunStatus] = useState();
    let [assignAllExistingAnotherRunStatus, setAssignAllExistingAnotherRunStatus] = useState();
    let [assignAllMissingPostCodeStatus, setAssignAllMissingPostCodeStatus] = useState();
    let [assignAllMissingSuburbStatus, setAssignAllMissingSuburbStatus] = useState();
    let [touched, setTouched] = useState(false);
    let [importing, setImporting] = useState(false);
    let [geoDocket, setGeoDocket] = useState();
    let [lat, setLat] = useState();
    let [long, setLong] = useState();
    let [showModal, setShowModal] = useState(false);

    var currentDepot = depotState.depots.find(d => d.depotId === depotId);

    var newDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 1) : null;

    var newDocketsList = newDockets && newDockets.length > 0 ? newDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var existingNoRunDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 2) : null;

    var existingNoRunDocketsList = existingNoRunDockets && existingNoRunDockets.length > 0 ? existingNoRunDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var existingAnotherRunDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 3) : null;

    var existingAnotherRunDocketsList = existingAnotherRunDockets && existingAnotherRunDockets.length > 0 ? existingAnotherRunDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var missingPostCodeDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 4) : null;

    var missingPostCodeDocketsList = missingPostCodeDockets && missingPostCodeDockets.length > 0 ? missingPostCodeDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var missingSuburbDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 5) : null;

    var missingSuburbDocketsList = missingSuburbDockets && missingSuburbDockets.length > 0 ? missingSuburbDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var invalidPostCodeDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 6) : null;

    var invalidPostCodeDocketsList = invalidPostCodeDockets && invalidPostCodeDockets.length > 0 ? invalidPostCodeDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var invalidSuburbsDockets = (dockets && dockets.length > 0) ? dockets.filter(x => x.rowStatus === 7) : null;

    var invalidSuburbsDocketsList = invalidSuburbsDockets && invalidSuburbsDockets.length > 0 ? invalidSuburbsDockets.map((docket, i) => {
        return (
            buildDocketRow(docket)
        )
    }) : null;

    var anyMissingGeocodes = dockets.filter(x => !x.hasGeoLocation).length > 0;
    // var docketsList = [];
    // if (newDocketsList && newDocketsList.length > 0) 
    //     docketsList.push(newDocketsList)
    
    // (dockets && dockets.length > 0) ?  dockets.map((docket, i) => {
    //     return ( 
    //         buildDocketRow(docket)
    //     )
    // }) : null;
    
    function buildHeaderRow() {
        var addressWidth = currentDepot.isMDA ? 3 : 4;

        return <Row className="importHeader">
                    <Col xs="1">Member No</Col>
                    <Col xs="1">Order No</Col>
                    <Col xs={addressWidth}>Address</Col>
                    <Col xs="1">No. of Boxes</Col>
                    <Col xs="1">Order Weight</Col>
                    {currentDepot.isMDA ? <Col xs="1">Order Volume</Col> : null}
                    <Col xs="2">Docket Status</Col>
                    <Col xs="2">Action</Col>
                </Row>;
    }

    var docketListHeader = buildHeaderRow();

    var anyMissingSuburbOrPostCode = dockets.filter(x => x.rowStatus === 4 || x.rowStatus === 5).length > 0;

    function buildDocketRow(docket) {
        var addressWidth = currentDepot.isMDA ? 3 : 4;

        return <Row key={docket.memberNo + "" + docket.orderNo}>
            <Col xs="1">{docket.memberNo}</Col>
            <Col xs="1">{docket.orderNo}</Col>
            <Col xs={addressWidth}>{docket.address}{!docket.hasGeoLocation &&<span className="missingGeocode" onClick={() => showGeoModal(docket)}><FaGlobe /></span>}</Col>
            <Col xs="1">{docket.boxCount}</Col>
            <Col xs="1">{docket.totalWeight}</Col>
            {currentDepot.isMDA ? <Col xs="1">{parseFloat(docket.totalVolume).toFixed(2)}</Col> : null}
            <Col xs="2">{rowImportStatusLabel(docket.rowStatus)}</Col>
            <Col xs="2">{(docket.rowStatus === 6 || docket.rowStatus === 7) && <FaExclamation style={{color: 'red' }}/>}{buildUserActionOptions(docket)}</Col>
        </Row>;
    }

    function rowImportStatusLabel(rowStatusId) {
        switch (rowStatusId)
        {
            case 1:
                return "New";
            case 2:
                return "Existing - No Run Assigned";
            case 3:
                return "Existing - Assigned to Another Run";
            case 4:
                return "Post Code not assigned to Depot";
            case 5:
                return "Suburb Not assigned to Depot";
            case 6:
                return "Invalid Post Code does not exist in database";
            case 7:
                return "Invalid Suburb"
        }
    }

    function buildUserActionOptions(docket, i) {
            if (docket.rowStatus === 2 || docket.rowStatus === 3)
            {
                return (<select id={"userAction" + i} value={docket.userActionId}>
                            <option key="-1" value="-1">Please Choose...</option>
                            <option key="1" value="1">Ignore Docket</option>
                            <option key="2" value="2">Move to Run</option>
                        </select>)
            }
            else if (docket.rowStatus === 1) {
                return "Ready to Import"
            }
            else {
                return "Docket will be ignored."
            }
    }

    function reImportFile(event) {
        setImporting(true);

        const data = new FormData();
        data.append('uploadedFile', file);
        data.append('depotId', parseInt(appState.selectedDepotId));
        data.append('deliverySeasonId', parseInt(appState.selectedDeliverySeasonId))
        fetch(BASEAPI + (currentDepot.isMDA ? '/api/ImportDockets/AddMissingAndRunMDAFirstPass' : '/api/ImportDockets/AddMissingAndRunFirstPass'), {
            method: 'POST',
            body: data,
            headers: {
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            setDockets(result.importedRows);
            depotDispatch({type: "ADD_POSTCODES_AND_SUBURBS", depotId: depotId, postCodes: result.addedPostCodes, suburbs: result.addedSuburbs});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Uploading File", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setImporting(false);
        })
    }

    function validDockets() {
        var invalidDockets = dockets.filter(x => (x.rowStatus === 2 || x.rowStatus === 3) && (!x.userActionId || x.userActionId < 1));

        return invalidDockets.length == 0;
    }

    function secondStep() {

        setImporting(true);

        if (validDockets()) {
            var uri = BASEAPI + (currentDepot.isMDA ? "/api/ImportDockets/ImportDocketSecondStepMDA" : "/api/ImportDockets/ImportDocketSecondStep");

            fetch(uri, {
                method: 'POST',
                body: JSON.stringify({
                    request: {
                        rowsToImport: dockets
                    },
                    deliverySeasonId: parseInt(appState.selectedDeliverySeasonId),
                    depotId: parseInt(appState.selectedDepotId),
                    fileName: file.name,
                    userName: userState.user
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                setImportFileId(result.importFileId);
                setSavedDockets(result.rowsImported);
            })
            .finally(() => {
                setImporting(false);
            })
        }
        else {
            alert("Please select an option for all Dockets requiring attention");
            setImporting(false);
        }
    }

    function assignAllExistingNoRun() {
        assignAll(existingNoRunDockets, assignAllNoRunStatus);
    }

    function assignAllExistingAnotherRun() {
        assignAll(existingAnotherRunDockets, assignAllExistingAnotherRunStatus);
    }

    function assignAllMissingPostCode() {
        assignAll(missingPostCodeDockets, assignAllMissingPostCodeStatus);
    }

    function assignAllMissingSuburb() {
        assignAll(missingSuburbDockets, assignAllMissingSuburbStatus);
    }

    function assignAll(dockets, status) {
        dockets.forEach(docket => {
            docket.userActionId = parseInt(status);
        });

        setTouched(!touched);
    }

    function showGeoModal(docket) {
        setGeoDocket(docket);
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
        setGeoDocket(null);
        setLat('');
        setLong('');
    }

    function isValidGeo() {
        if (validLat())
        {
            if (validLong())
            {
                return true;
            }
        }

        return false;
    }

    function validLat() {
        if (lat && lat.length > 0)
        {
            if (lat >= -90 && lat <= 90)
            {
                return true;
            }
        }

        return false;
    }

    function validLong() {
        
        if (long && long.length > 0)
        {
            if (long >= -180 && long <= 180)
            {
                return true;
            }
        }

        return false;
    }

    function updateGeocode() {
        fetch(BASEAPI + "/api/address/UpdateGeocode", {
            method: 'POST',
                body: JSON.stringify({
                    addressId: geoDocket.addressId,
                    latitude: lat,
                    longitude: long
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            //update docket, plus update docket of any others sharing the address
            if (result.hasGeocode) {
                var matchingDockets = dockets.filter(x => x.addressId == geoDocket.addressId);
                matchingDockets.forEach(x => {
                    x.hasGeoLocation = true
                });

                closeModal();
            }

        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating GeoCode", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <div>
        {savedDockets &&
            <ImportDocketComplete fileName={file.name} dockets={savedDockets} importFileId={importFileId} />
        }
        {!savedDockets &&
            <Container fluid className="full-height bg-light">
            <Row>
                <Col xs="10" lg="auto">
                    <h3>Confirm action to import {file.name} for {depotName} Depot</h3>
                </Col>
            </Row>
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">  
                <Col xs="10" lg="auto" className="p-0">
                    {anyMissingSuburbOrPostCode && 
                    <Card body inverse color="primary" className="text-center" style={{ width: '18rem' }}>
                        <CardTitle>Import and Add Post Codes and Suburb</CardTitle>
                        <CardText>
                            This import contains rows that have a Post Code or Suburb not supported by Depot.
                            Select to Add all Post Codes and Suburbs from this file to this Depot and re-validate the file.
                        </CardText>
                        <Button color="secondary" onClick={reImportFile} disabled={importing || anyMissingGeocodes}>Re-Import</Button>
                    </Card>}
                </Col>
            </Row>
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Card body inverse color="primary" className="text-center" style={{ width: '18rem'}}>
                        <CardTitle>Submit Dockets</CardTitle>
                        <CardText>
                            Check those dockets that need a user action.  
                            If a docket already exists on another run, either choose to clear it from existing run or ignore from this run.
                        </CardText>
                        <Button color="secondary" onClick={secondStep} disabled={importing || anyMissingGeocodes}>Submit</Button>
                    </Card>
                </Col>
            </Row>
            {anyMissingGeocodes && 
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <Alert color="warning">Dockets contain addresses that could not be geocoded, please manually update those before submitting.</Alert>
                </Col>
            </Row>}
            {invalidPostCodeDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>Invalid Post Codes</h4> <Badge color="warning">These Post Codes are not in master list in Database - Contact IT</Badge>
                </Col>
            </Row>
            }
            {invalidPostCodeDocketsList && 
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {invalidPostCodeDocketsList}
                    </Container>
                </Col>
            </Row>
            }
            {invalidSuburbsDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>Invalid Suburbs</h4> <Badge color="warning">These Suburbs are not in master list in Database - Contact IT</Badge>
                </Col>
            </Row>
            }
            {invalidSuburbsDocketsList && 
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {invalidSuburbsDocketsList}
                    </Container>
                </Col>
            </Row>
            }
            {newDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>New Dockets</h4>
                </Col>
            </Row>
            }
            {newDocketsList && 
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {newDocketsList}
                    </Container>
                </Col>
            </Row>
            }
            {existingNoRunDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>Existing Dockets - Currently not in a Run</h4>  <select id="allNoRun" onChange={(e) => setAssignAllNoRunStatus(e.target.value)} value={assignAllNoRunStatus}>
                            <option key="-1" value="-1">Please Choose...</option>
                            <option key="1" value="1">Ignore Docket</option>
                            <option key="2" value="2">Move to Run</option>
                        </select>
                    <Button colour="info" onClick={assignAllExistingNoRun}>Assign All</Button>
                </Col>
            </Row>
            }
            {existingNoRunDocketsList && 
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {existingNoRunDocketsList}
                    </Container>
                </Col>
            </Row>
            }
            {existingAnotherRunDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>Existing Dockets - Currently in Another Run</h4> <select id="allAnotherRun" onChange={(e) => setAssignAllExistingAnotherRunStatus(e.target.value)} value={assignAllExistingAnotherRunStatus}>
                            <option key="-1" value="-1">Please Choose...</option>
                            <option key="1" value="1">Ignore Docket</option>
                            <option key="2" value="2">Move to Run</option>
                        </select>
                    <Button colour="info" onClick={assignAllExistingAnotherRun}>Assign All</Button>
                </Col>
            </Row>
            }
            {existingAnotherRunDocketsList &&
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {existingAnotherRunDocketsList}
                    </Container>
                </Col>
            </Row>
            }

            {missingPostCodeDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                    <h4>Dockets where Depot is Missing Post Code</h4>
                    {/* <select id="allMissingPostCode" onChange={(e) => setAssignAllMissingPostCodeStatus(e.target.value)} value={assignAllMissingPostCodeStatus}>
                            <option key="-1" value="-1">Please Choose...</option>
                            <option key="1" value="1">Ignore Docket</option>
                        </select>
                    <Button colour="info" onClick={assignAllMissingPostCode}>Assign All</Button> */}
                </Col>
            </Row>
            }
            {missingPostCodeDocketsList &&
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {missingPostCodeDocketsList}
                    </Container>
                </Col>
            </Row>
            }

            {missingSuburbDocketsList &&
            <Row>
                <Col xs="10" lg="auto" className="p-0">
                   <h4>Dockets where Depot is missing Suburb</h4>
                   {/* <select id="allMissingSuburb" onChange={(e) => setAssignAllMissingSuburbStatus(e.target.value)} value={assignAllMissingSuburbStatus}>
                            <option key="-1" value="-1">Please Choose...</option>
                            <option key="1" value="1">Ignore Docket</option>
                        </select>
                    <Button colour="info" onClick={assignAllMissingSuburb}>Assign All</Button> */}
                </Col>
            </Row>
            }
            {missingSuburbDocketsList &&
            <Row className="h-100 justify-content-center full-height align-items-center bg-light">
                <Col xs="10" lg="auto" className="p-0">
                    <Container>
                        {docketListHeader}
                        {missingSuburbDocketsList}
                    </Container>
                </Col>
            </Row>
            }

        </Container>}
        <Modal isOpen={showModal}>
            <ModalHeader>Enter Geo Coordinates for {geoDocket ? geoDocket.address : ''}</ModalHeader>
            <ModalBody>
                <div>
                    Please enter geo coordinates for {geoDocket ? geoDocket.address : ''}
                </div>
                <div>
                    <Label>
                        Latitude
                        <Input type="text" id="txtLat" value={lat} onChange={e => setLat(e.target.value)} invalid={!validLat()} />
                        <FormFeedback valid={validLat()}>Please enter a valid latitude between -90 and 90 degrees</FormFeedback>
                    </Label>
                    <Label>
                        Longitude
                        <Input type="text" id="txtLong" value={long} onChange={e => setLong(e.target.value)} invalid={!validLong()} />
                        <FormFeedback valid={validLong()}>Please enter a valid longitude between -180 and 180 degrees</FormFeedback>
                    </Label>
                    
                </div>
            </ModalBody>
            <ModalFooter>
                <Button disabled={!isValidGeo()} color="primary" onClick={updateGeocode}>Submit</Button>
                <Button color="secondary" onClick={closeModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
        </div>)
}