import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <div>
          Welcome to Delivery Router App
          <ul>
            <li><Button color="link" tag={Link} to="/Config">Config</Button> - To Configure Depots (Trucks, Locations, Suburbs Covered) and Delivery Dates</li>
            <li><Button color="link" tag={Link} to="/Route">Route</Button> - To Import Dockets and Create Routes</li>
            {/* <li><Button color="link" tag={Link} to="/Manage">Manage</Button> - To Manage a Season of Runs</li>
            <li><Button color="link" tag={Link} to="/manageUsers">Users</Button> - To Manage External Logins and the depots they can see</li> */}
          </ul>
        </div>
        
      </div>
    );
  }
}
