import React, {createContext, useContext, useReducer } from "react";

let AppContext = createContext();

export function useAppState() {
    return useContext(AppContext);
}

const appStateReducer = (state, action) => {
    switch (action.type) {
        case "INIT_SEASONS":
            return {...state, deliverySeasons: action.deliverySeasons};
        case "ADD_SEASON":
            return {...state, deliverySeasons: state.deliverySeasons.concat(action.deliverySeason)};
        case "SELECT_SEASON":
            console.log("setting season to :" + action.deliverySeasonId);
            return {...state, selectedDeliverySeasonId: action.deliverySeasonId, selectedDepotId: -1};
        case "SELECT_DEPOT":
            return {...state, selectedDepotId: action.depotId};
        default:
            return state;
    }
}

const initialState = {
    deliverySeasons: [],
    selectedDeliverySeasonId: null,
    selectedDepotId: -1
};

export function AppStateProvider({children}) {
    let appState = useReducer(appStateReducer, initialState);

    return <AppContext.Provider value={appState}>{children}</AppContext.Provider>
}