import React from "react";
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import Config from './components/Config';
import DepotEdit from './components/DepotEdit';
import RouteDockets from './components/RouteDockets';
import RouteEngine from './components/RouteEngine';
import ImportDocketFile from './components/ImportDocketFile';
import ImportFileRuns from "./components/ImportFileRuns";
import RunDetails from "./components/RunDetails";
import Manage from "./components/Manage";
import TaskList from "./components/TaskList";
import ImportDocketFileSecondPhase from "./components/ImportDocketFileSecondPhase";
import FailedRoute from "./components/FailedRoute";
import UserManagement from "./components/UserManagement";
import SearchAddresses from "./components/SearchAddresses";

export default function AppLayout() {
    return (
        <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/config'>
              <Config />
            </Route>
            <Route path='/depot/:depotId'>
              <DepotEdit />
            </Route>
            <Route path='/route'>
              <RouteDockets />
            </Route>
            <Route path="/engine/:importFileId">
              <RouteEngine />
            </Route>
            <Route path='/import'>
              <ImportDocketFile />
            </Route>
            <Route path='/importSecond'>
              <ImportDocketFileSecondPhase />
            </Route>
            <Route path='/importFileRuns/:importFileId'>
              <ImportFileRuns />
            </Route>
            <Route path='/run/:runId'>
              <RunDetails />
            </Route>
            <Route path='/manage'>
              <Manage />
            </Route>
            <Route path='/tasks'>
              <TaskList />
            </Route>
            <Route path='/failedRoute/:importFileId'>
              <FailedRoute />
            </Route>
            <Route path='/counter' component={Counter} />
            <Route path='/fetch-data' component={FetchData} />
            <Route path='/manageUsers'>
              <UserManagement />
            </Route>
            <Route path="/addresses" component={SearchAddresses} />
          </Layout>
    );
}