import _, { update } from "lodash";
import React, { useState } from "react";
import { FaGlobe } from "react-icons/fa";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Select, Table } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import AddressEdit from "./AddressEdit";
import AddressView from "./AddressView";
import { handleErrors } from "./Helpers";

export default function SearchAddresses() {
    let [addressLine1, setAddressLine1] = useState();
    let [suburb, setSuburb] = useState();
    let [postCode, setPostCode] = useState();
    let [state, setState] = useState('');

    let [addresses, setAddresses] = useState();

    let [showAddressEditModal, setShowAddressEditModal] = useState(false);
    let [currentAddress, setCurrentAddress] = useState();

    function resetForm() {
        setAddressLine1('');
        setSuburb('');
        setPostCode('');
        setState('');
    }

    function search() {
        fetch(BASEAPI + "/api/Address/searchAddresses", 
        {
            method: "POST",
            body: JSON.stringify({
                addressLine1: addressLine1,
                suburb: suburb,
                state: state,
                postCode: postCode
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => { 
            setAddresses(result);
        })           
    }

    function editAddress(addressId) {
        var address = addresses.find(x => x.addressId == addressId);
        setCurrentAddress(address);
        setShowAddressEditModal(true);
    }

    function cancelEdit() {
        setShowAddressEditModal(false);
        setCurrentAddress(null);
    }

    function updateAddress(updatedAddress) {
        var index = addresses.findIndex(x => x.addressId == updatedAddress.addressId);

        addresses[index] = updatedAddress;
        setAddresses(addresses);

        cancelEdit();
    }

    return (
        <div>
            <h2>Search Addresses</h2>

            <div className="formGroup">
                <div className="groupTitle">Search Fields</div>

                <Form>
                    <FormGroup row>
                        <Label for="addressLine1" sm={2}>Address Line 1</Label>
                        <Col sm={10}>
                            <Input type="text" name="addressLine1" id="addressLine1" className="textInput" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="suburb" sm={2}>Suburb</Label>
                        <Col sm={10}>
                            <Input type="text" name="suburb" id="suburb" className="textInput" value={suburb} onChange={(e) => setSuburb(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="postCode" sm={2}>Post Code</Label>
                        <Col sm={10}>
                            <Input type="text" name="postCode" id="postCode" className="textInput" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column sm={2} for="state">State</Label>
                        <Col sm={10}>
                            <Input type="select" id="state" value={state} onChange={e => setState(e.target.value)}>
                                <option value="">Please Select...</option>
                                <option value="NSW">New South Wales</option>
                                <option value="VIC">Victoria</option>
                                <option value="QLD">Queensland</option>
                                <option value="SA">South Australia</option>
                                <option value="WA">Western Australia</option>
                                <option value="TAS">Tasmania</option>
                                <option value="ACT">ACT</option>
                                <option value="NT">Northern Territory</option>
                            </Input>
                        </Col>
                    </FormGroup>
                </Form>

                <div>
                    <Button color="primary" onClick={search}>Submit</Button>
                    <Button color="warning" onClick={resetForm}>Reset</Button>
                </div>
            </div>

            {addresses && <div>
                    <h3>Search Results</h3>

                    <small>{addresses.length} addresses found</small>

                    <Table striped>
                        <tbody>
                    {addresses.map((address, index) => {
                        return (
                            <tr index={index}><td><AddressView address={address} /> {address.hasGeocode ? <FaGlobe color="green" /> : <FaGlobe color="red" />} <Button size="sm" color="warning" onClick={() => editAddress(address.addressId)}>Edit</Button></td></tr>
                        )
                    })}
                        </tbody>
                    </Table>
                </div>}

            <Modal isOpen={showAddressEditModal} fade={true}>
                <ModalHeader>Edit Address</ModalHeader>
                <ModalBody>
                    <AddressEdit address={currentAddress} updatedAddress={updateAddress} />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={cancelEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}