import { add } from "date-fns";
import React, { useEffect, useState } from "react";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { Badge, Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table } from "reactstrap";
import { useAppState } from "../contexts/AppContext";
import { BASEAPI } from "../contexts/constants";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";
import { addOrEditEnum } from "./Enums";
import { handleErrors, isEmpty } from "./Helpers";

import "./user.css";
import UserForm from "./UserForm";

export default function UserManagement() {
    let [loading, setLoading] = useState(false);
    let [users, setUsers] = useState([]);
    let [userBeingEdited, setUserBeingEdited] = useState(null);
    let [editMode, setEditMode] = useState(addOrEditEnum.Add);
    let [showSetPasswordModal, setShowSetPasswordModal] = useState(false);

    let [setPasswordUserId, setSetPasswordUserId] = useState(null);
    let [password, setPassword] = useState();
    let [passwordConfirm, setPasswordConfirm] = useState();

    let [appState, dispatchAppState] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    useEffect(() => {
        fetchUsers();
    }, [])

    function fetchUsers() {
        setLoading(true);
        fetch(BASEAPI + "/api/User/",{
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setUsers(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Users", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    function saveNewUser(user) {
        var newUsers = users.concat(user);
        setUsers(newUsers);
        setUserBeingEdited(null);
    }

    function updateUser(user) {
        var userIndex = users.findIndex(x => x.userId == user.userId);

        var newUsers = users.map((u, i) => {
            return i === userIndex ? user : u;
        });
        setUsers(newUsers);
        setUserBeingEdited(null);
    }

    function addUser() {
        var newUser = {
            userId: null,
            userName: null,
            depots: []
        }
        setEditMode(addOrEditEnum.Add);
        setUserBeingEdited(newUser);
    }

    function cancelledUpdate() {
        setUserBeingEdited(null);
    }

    function editUser(user) {
        setEditMode(addOrEditEnum.Edit);
        setUserBeingEdited(user);
    }

    function setNewPassword(userId) {
        setSetPasswordUserId(userId);
        setShowSetPasswordModal(true);
    }

    function cancelSetPassword() {
        setShowSetPasswordModal(false);
    }

    function sendNewPasswordToServer() {
        var newPasswordRequest = {
            userId: setPasswordUserId,
            newPassword: password
        };

        fetch(BASEAPI + "/api/User/SetPasswordForUser", 
        {
            method: "POST",
            body: JSON.stringify(newPasswordRequest),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) 
                setShowSetPasswordModal(false);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Users", errorMessage:"Server Error: " + error.message});
        })
    }

    function removeUser(user) {
        if (window.confirm('Are you sure you want to remove user ' + user.userName + '?')) {
            fetch(BASEAPI + "/api/User/RemoveUser/" + user.userId, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                if (result) {
                    //Todo remove user from collection
                    var updatedUsers = users.filter(x => x.userId != user.userId);
                    setUsers(updatedUsers);
                }
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error removing user", errorMessage:"Server Error: " + error.message});
            })
        }
    }

    return (
        <div>
            <h1>User Management</h1>

            {userBeingEdited && editMode == addOrEditEnum.Add &&
                <UserForm userUpdated={saveNewUser} updateCancelled={cancelledUpdate} addOrEdit={addOrEditEnum.Add} user={userBeingEdited} />
            }
            {userBeingEdited && editMode == addOrEditEnum.Edit &&
                <UserForm userUpdated={updateUser} updateCancelled={cancelledUpdate} addOrEdit={addOrEditEnum.Edit} user={userBeingEdited} />
            }
            {!userBeingEdited && <>
            <div className="addUserContainer">
                <Button size="sm" color="primary" onClick={addUser}><FaPlus />Add User</Button>
            </div>
            <div>
            {loading && <Spinner color="success" />}
            {!loading && <Table striped>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th width="65%">Depots</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
            {users != null && users.map((user, index) => {
                return (<tr key={index}>
                    <td>{user.userName}</td>
                    <td width="65%">{user.depots.map((depot, index) => {
                        var delSeason = appState.deliverySeasons.find(x => x.deliverySeasonId == depot.deliverySeasonId);
                        return (
                          <><Badge color="info" pill>{depot.name + "(" + delSeason.name + ")"}</Badge>{' '}</>
                        )
                    })}</td>
                    <td>
                        <Button color="warning" size="sm" onClick={() => editUser(user)}><FaEdit />Edit</Button>{' '}
                        <Button color="primary" size="sm" onClick={() => setNewPassword(user.userId)}><MdOutlinePassword /> Set Password</Button>{' '}
                        <Button color="danger" size="sm" onClick={() => removeUser(user)}><FaTrashAlt /></Button>
                    </td>
                </tr>)
            })}
            </tbody>
            </Table>
            }
            </div>
            <Modal isOpen={showSetPasswordModal} fade={true}>
                <ModalHeader>Set New Password for User</ModalHeader>
                <ModalBody>
                    <FormGroup row>
                        <Label column sm="2" for="password"> Password:</Label>
                        <Col sm="10">
                            <Input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} invalid={isEmpty(password)} />
                            <FormFeedback valid={!isEmpty(password)}>Please Enter A Password</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label column sm="2" for="passwordConfirm"> Confirm Password:</Label>
                        <Col sm="10">
                            <Input type="password" id="passwordConfirm" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} invalid={isEmpty(passwordConfirm) || (password != passwordConfirm)} />
                            <FormFeedback valid={!isEmpty(password) && (password == passwordConfirm)}>Please Confirm Password and make sure it matches</FormFeedback>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={isEmpty(password) || password != passwordConfirm} onClick={sendNewPasswordToServer}>Set Password</Button>
                    <Button onClick={cancelSetPassword}>Cancel</Button>
                </ModalFooter>
            </Modal>

            </>}
        </div>
    )
}