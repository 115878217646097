import React, { useState } from "react";
import { Alert, Button, Label } from "reactstrap";
import { BASEAPI } from "../../contexts/constants";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { handleErrors } from "../Helpers";
import { useSecondPhaseFileState } from "../../contexts/SecondPhaseFileContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

export default function SequenceDocket({dockets, noOfDocketsInRun, index, runsUpdated, sequenceUpdated}) {
    let docket = dockets && dockets.length > 0 ? dockets[0] : null;
    let [successMessage, setSuccessMessage] = useState();

    let [errorState, dispatchServerError] = useServerErrorState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();

    function moveDown(docketId) {
        fetch(BASEAPI + "/api/run/MoveDocketDown", {
            method: "POST",
            body: docketId,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                runsUpdated([result]);
                var seqNumbers = [];

                dockets.forEach(x => {
                    var newDocket = result.dockets.find(y => y.docketId === x.docketId);
                    seqNumbers.push(newDocket.sequenceNumber);
                })
                
                sequenceUpdated(docketId, seqNumbers);
                if (secondPhaseFileState.selectedImportFileId)
                    dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    function moveUp(docketId) {
        fetch(BASEAPI + "/api/run/MoveDocketUp", {
            method: "POST",
            body: docketId,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                runsUpdated([result]);
                var seqNumbers = [];

                dockets.forEach(x => {
                    var newDocket = result.dockets.find(y => y.docketId === x.docketId);
                    seqNumbers.push(newDocket.sequenceNumber);
                })
                
                sequenceUpdated(docketId, seqNumbers);
                if (secondPhaseFileState.selectedImportFileId)
                    dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result});
                setSuccessMessage("Sequence Moved Successfully");    
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <>
             <div>
                <Label>New Sequence</Label>
                <div>Current Sequence No: {docket.sequenceNumber}</div>
                {/* <div>? allow for setting sequence directly?</div> */}
                {index > 0 && <Button color="primary" onClick={() => moveUp(docket.docketId)}><FaArrowUp /></Button>}
                {index < noOfDocketsInRun-1 && <Button color="primary" onClick={() => moveDown(docket.docketId)}><FaArrowDown /></Button>}
                {successMessage && 
                    <Alert color="success">{successMessage}</Alert>}
            </div>
        </>
    )
}