import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaPlus, FaFolderPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "./Config.css"
import { BASEAPI } from "../contexts/constants";
import { useDepotState } from "../contexts/DepotContext";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function DepotSuburb({depot}) {
    let [depotState, dispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    let [searchText, setSearchText] = useState('');
    let [allSuburbs, setAllSuburbs] = useState();
    let [searchResults, setSearchResult] = useState(null);

    function search(event) {
        setSearchText(event.target.value);

        if (event.target.value.length > 2) {
            var results = allSuburbs.filter(x => x.name.includes(event.target.value.toUpperCase()));
            setSearchResult(results);
        }
        else {
            setSearchResult(null);
        }
    }

    function addSuburb(suburbId, allSuburbs) {
        fetch(BASEAPI + "/api/Depot/AssignSuburbToDepot", {
            method: "POST",
            body: JSON.stringify({
                depotId: depot.depotId,
                suburbIds: [suburbId]
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            dispatch({type: "ADD_POSTCODES_AND_SUBURBS", depotId: depot.depotId, postCodes: result.postCodes, suburbs: result.suburbs});
            setSearchText('');
            setSearchResult(null);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Suburb to Depot", errorMessage:"Server Error: " + error.message});
        })
    }

    function deleteSuburb(suburbId) {
        fetch(BASEAPI + "/api/Depot/RemoveSuburbFromDepot", {
            method: "POST",
            body: JSON.stringify({
                depotId: depot.depotId,
                suburbId: suburbId
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                dispatch({type: "REMOVE_SUBURB", depotId: depot.depotId, suburbId: suburbId});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Removing Suburb from Depot", errorMessage:"Server Error: " + error.message});
        })
    }

    function getAllSuburbs() {
        fetch(BASEAPI + "/api/Config/GetAllSuburbs", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setAllSuburbs(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Suburbs", errorMessage:"Server Error: " + error.message});
        })
    }

    useEffect(() => {
        getAllSuburbs();
    }, [])

    return (
        <div>
            <div className="suburb-grid">
                {depot.suburbs && depot.suburbs.map((suburb, index) => {
                    return (
                        <div>{suburb.name} <Button size="sm" color="secondary" onClick={() => deleteSuburb(suburb.suburbId)}><MdDelete /></Button></div>
                    )
                })}
            </div>
            <div>
                <input type="search" name="suburbSearch" id="suburbSearch" placeholder="Search for a Suburb" onChange={search} value={searchText} />
                <div id="searchResults">
                {searchResults && 
                    searchResults.map((suburb, index) => {
                        return (
                            <div>
                                <div>{suburb.name}</div>
                                <div>
                                    <Button color="primary" size="sm" onClick={() => addSuburb(suburb.suburbId)}><FaPlus /></Button>
                                </div>
                            </div>
                        );
                })}
                </div>
            </div>
        </div>
    )
}