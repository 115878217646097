import React from "react";
import { useDepotState } from "../contexts/DepotContext";

export default function TruckTypeTileInfoPanel({truckType}) {
    let [state, dispatch] = useDepotState();

    let depot = state.depots.find(x => x.depotId === parseInt(truckType.depotId));

    return (
        <div>
                <div className="truckTypeDesc">Truck Type: {truckType.description}</div>
                <div>Truck Count: {truckType.quantity}</div>
                <div className="ttPerTruck">Per Truck:
                    <div>Max Boxes: {truckType.maxBoxCount}</div>
                    <div>Max Weight: {truckType.maxWeight}</div>
                    {depot.isMDA && 
                    <div>Max Volume: {truckType.maxVolume}</div>}
                </div>
            </div>
    )
}