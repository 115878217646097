import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../contexts/AppContext';
import { BASEAPI } from '../contexts/constants';
import { Spinner, Button, InputGroup, Collapse, Label, Alert } from 'reactstrap';
import { useDepotState } from '../contexts/DepotContext';
import RouteProgress from './RouteProgress';
import RunsList from './RunsList';
import AssignDeliveryDate from './AssignDeliveryDate';
import { handleErrors } from './Helpers';
import { useServerErrorState } from '../contexts/ServerErrorContext';
import { useUserState } from '../contexts/UserContext';

export default function RouteEngine() {
    const POLL_TIMEOUT = 10000;

    let mounted = false;

    let { importFileId } = useParams();
    let [importFile, setImportFile] = useState();
    let [depotState, depotDispatch] = useDepotState();
    let [appState, appDispatch] = useAppState();
    let [userState, userDispatch] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    let [distanceServiceId, setDistanceServiceId] = useState(5);
    let [constrainOnBoxes, setConstrainOnBoxes] = useState(true);
    let [constrainOnWeight, setConstrainOnWeight] = useState(false);
    let [constrainOnVolume, setConstrainOnVolume] = useState(true);
    let [taskId, setTaskId] = useState();
    let [progressEvents, setProgressEvents] = useState();
    let [runs, setRuns] = useState();
    let [overflowRuns, setOverflowRuns] = useState();
    let [showAssignDate, setShowAssignDate] = useState();
    let [queueing, setQueueing] = useState(false);

    let depot = importFile ? depotState.depots.find(x => x.depotId === importFile.depotId) : null;

    function queueRoute() {
        setQueueing(true);

        var data = (depot && depot.isMDA) ? {
            depotId: depot.depotId,
            importFileId: importFile.importFileId,
            generateOverflowRuns: true, //TODO add to form
            distanceServiceId: parseInt(distanceServiceId),
            constrainOnBoxes: constrainOnBoxes,
            constrainOnWeight: constrainOnWeight,
            constrainOnVolume: constrainOnVolume
            } : {
                depotId: depot.depotId,
                importFileId: importFile.importFileId,
                generateOverflowRuns: true,
                distanceServiceId: parseInt(distanceServiceId),
                constrainOnBoxes: constrainOnBoxes,
                constrainOnWeight: constrainOnWeight,
                constrainOnVolume: null
                };

        fetch(BASEAPI + "/api/Route/QueueRoute", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setTaskId(result.taskId);
            setTimeout(() => { 
                mounted = true; 
                pollResults(result.taskId) 
            }, POLL_TIMEOUT);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Queueing Route", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setQueueing(false);
        })
    }

    function pollResults(id) {
        if (mounted) {
            fetch(BASEAPI + "/api/Route/CheckRouteProgress/" + id,
            {
                headers: {
                  Authorization: 'Bearer ' + userState.token
                }
              })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                setProgressEvents(result.routeProgressEvents);
                if (result.isComplete) {
                    getResults(id);
                } else {
                    setTimeout(() => { pollResults(id) }, POLL_TIMEOUT);
                }
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Task Status", errorMessage:"Server Error: " + error.message});
                setTimeout(() => { pollResults(id) }, POLL_TIMEOUT)
            })
        }
    }

    function getResults(id) {
        fetch(BASEAPI + "/api/Route/RetrieveRouteResult/" + id,
        {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRuns(result.deliveryRuns);
            setOverflowRuns(result.overflowDeliveryRuns);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Route Task Results", errorMessage:"Server Error: " + error.message});
        })
    }

    function toggleAssignDate() {
        setShowAssignDate(!showAssignDate);
    }

    function constrainOnBoxesChange(event) {
        setConstrainOnBoxes(!constrainOnBoxes);
    }

    function constrainOnWeightChange(event) {
        setConstrainOnWeight(!constrainOnWeight);
    }

    function constrainOnVolumeChange(event) {
        setConstrainOnVolume(!constrainOnVolume);
    }

    useEffect(() => {
        mounted = true;

        fetch(BASEAPI + "/api/ImportDockets/" + importFileId,
        {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setImportFile(result);
            if (result.taskId) {
                setTaskId(result.taskId);
                setTimeout(pollResults(result.taskId), POLL_TIMEOUT);
            }
                
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Dockets for Import File", errorMessage:"Server Error: " + error.message});
        })

        return function cleanup() {
            mounted = false
        }
    }, [])

    return (
        <div>
            {!importFile && <Spinner color="primary" ></Spinner>}    
            {importFile &&    
                <div>
                    <h3>Route {importFile.fileName} Dockets</h3>
                    <div>
                        {importFile.docketCount} are ready to be imported in {depot.name} depot
                    </div>                    
                    <Label>Distance Engine
                        <select id="distanceService" value={distanceServiceId} onChange={(event) => setDistanceServiceId(event.target.value)}>
                            {userState.isDevUser &&
                                <option value="1">Mappify</option>}
                            {/* <option value="2">Google</option> */}
                            {userState.isDevUser &&
                                <option value="3">Mappify Euclidean</option>
                            }
                            {userState.isDevUser &&
                                <option value="4">Random Test</option>
                            }
                            <option value="5">GraphHopper (local)</option>
                        </select></Label>
                    <InputGroup>
                        <Label>Constrain On Boxes<input type="checkbox" defaultChecked={constrainOnBoxes} onChange={constrainOnBoxesChange} /></Label>
                        <Label>Constrain On Weight<input type="checkbox" defaultChecked={constrainOnWeight} onChange={constrainOnWeightChange} /></Label>
                        {depot.isMDA && <Label>Constrain on Volume<input type="checkbox" defaultChecked={constrainOnVolume} onChange={constrainOnVolumeChange} /></Label>}
                    </InputGroup>
                    {(!constrainOnBoxes && !constrainOnWeight) && 
                        <Alert color="warning">A constraint must be selected</Alert>}
                    <Button color="primary" onClick={queueRoute} disabled={!userState.canRoute || queueing || !!taskId || (!constrainOnBoxes && !constrainOnWeight)}>Route</Button>
                    {!userState.canRoute &&
                    <Alert color="warning">Route Queuing is disabled currently.  Please contact administrator.</Alert>}
                </div>
            }
            {taskId && 
            <div>
                Task {taskId} queued and running... 
                {!runs && <Spinner color="primary" />}

                <RouteProgress progressEvents={progressEvents} />

                {/* {runs && 
                    <div>
                        <Button onClick={toggleAssignDate}>Assign Date</Button>
                        <Collapse isOpen={showAssignDate}>
                            <div>test</div>
                            <AssignDeliveryDate runs={runs} />
                        </Collapse>
                    </div>
                } */}

                {runs && 
                    <div>
                        <h3>Run Results</h3>
                        <RunsList runs={runs} />
                    </div>
                }
                {overflowRuns &&
                    <div>
                        <h3>Extra Runs</h3>
                        <RunsList runs={overflowRuns} />
                    </div>
                }
            </div>
            }
        </div>
    )
}