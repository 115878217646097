import React, { useState } from "react";
import { Button } from "reactstrap";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function TruckTypeTileEditPanel({truckType}) {
    let [state, dispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    let depot = state.depots.find(x => x.depotId === parseInt(truckType.depotId));

    const [description, setDescription] = useState(truckType.description);
    const [vehicleCount, setCount] = useState(truckType.quantity);
    const [maxBoxCount, setMaxBoxCount] = useState(truckType.maxBoxCount);
    const [maxWeight, setMaxWeight] = useState(truckType.maxWeight);
    const [maxVolume, setMaxVolume] = useState(truckType.maxVolume ?? 0);

    function save(depotId, truckTypeId) {
        fetch(BASEAPI + "/api/Depot/" + depotId + "/TruckType", {
            method: "PUT",
            body: JSON.stringify({
                truckTypeId: truckTypeId,
                depotId: depotId,
                description: description,
                quantity: vehicleCount,
                maxBoxCount: maxBoxCount,
                maxWeight: maxWeight,
                maxVolume: depot.isMDA ? maxVolume : null,
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            } 
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            dispatch({type: "UPDATE_TRUCKTYPE", depotId: depotId, truckTypeId: truckTypeId, truckType: result});
            dispatch({type: "TOGGLE_TRUCKTYPE_EDITING", depotId: depotId, truckTypeId: truckTypeId});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating TruckType", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <div>
            <form className="form-inline truckForm">
                <div><label>Truck Type:<input type="text" value={description} onChange={e => setDescription(e.target.value)} /></label></div>
                <div><label>Truck Count: <input type="number" value={vehicleCount}  onChange={e => setCount(parseInt(e.target.value))} /></label></div>
                <div className="ttPerTruck">Per Truck:
                    <div><label>Max Boxes: <input type="number" value={maxBoxCount} className="ttNumInput" onChange={e => setMaxBoxCount(parseInt(e.target.value))} /></label></div>
                    <div><label>Max Weight: <input type="number" value={maxWeight} className="ttNumInput" onChange={e => setMaxWeight(parseInt(e.target.value))} /></label></div>
                    {depot.isMDA &&
                        <div><label>Max Volume: <input type="number" value={maxVolume} className="ttNumInput" onChange={e => setMaxVolume(parseInt(e.target.value))} /></label></div>
                    }
                </div>
                <div className="bottomBtns">
                    <Button size="sm" color="primary" onClick={() => save(truckType.depotId, truckType.truckTypeId)}>Save</Button>
                    <Button size="sm" color="secondary" onClick={() => dispatch({type: "TOGGLE_TRUCKTYPE_EDITING", depotId: truckType.depotId, truckTypeId: truckType.truckTypeId})}>Cancel</Button>
                </div>
            </form>
        </div>
    )
}