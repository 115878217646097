import React, { useEffect } from 'react';

import { Label, Spinner, Button, Card, CardTitle, CardBody, Container, Row, Col } from 'reactstrap';

import { BASEAPI } from './contexts/constants';
import { useAppState } from './contexts/AppContext';

import './custom.css'
import { useDepotState } from './contexts/DepotContext';
import AppLayout from './AppLayout';
import { useDeliveryDateState } from './contexts/DeliveryDateContext';
import { useUserState } from './contexts/UserContext';
import { useServerErrorState } from './contexts/ServerErrorContext';
import ErrorToast from './components/ErrorToast';
import { handleErrors, onlyUnique } from './components/Helpers';
import { FaPlus } from 'react-icons/fa';
import Login from './components/Login';

export default function App() {
  //let displayName = App.name;
  let [userState, userDispatch] = useUserState();
  let [appState, dispatch] = useAppState();
  let [depotState, depotDispatch] = useDepotState();
  let [deliveryDateState, dateDispatch] = useDeliveryDateState();
  let [serverErrorState, dispatchServerError] = useServerErrorState();

  function fetchUser() {
    fetch(BASEAPI + "/api/Account")
    .then(handleErrors)
    .then(res => res.json())
    .then((result) => {
      if (result.isAuthenticated) {
        userDispatch({type: "INIT", user: result.userName, canRoute: result.canRoute});
      }
    })
    .catch(function(error){
      console.log(error);
      dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching User", errorMessage:"Server Error: " + error.message});
  })
  }

  function fetchDeliverySeasons() {
    fetch(BASEAPI + "/api/DeliverySeason")
    .then(handleErrors)
    .then(res => res.json())
    .then((result) => {
        dispatch({type: 'INIT_SEASONS', deliverySeasons: result})
    })
    .catch(function(error){
      console.log(error);
      dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Delivery Seasons", errorMessage:"Server Error: " + error.message});
  })
  }

  function fetchDepots(deliverySeasonId) {
    var userId = userState.userId;
    fetch(BASEAPI + "/api/Depot/GetDepotsForUser/" + userId + "?deliverySeasonId=" + deliverySeasonId,
    {
      headers: {
        Authorization: 'Bearer ' + userState.token
      }
    })
    .then(handleErrors)
    .then(res => res.json())
    .then((result) => {
      depotDispatch({type: 'INIT', depots: result})
    })
    .catch(function(error){
      console.log(error);
      dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Depots for Season", errorMessage:"Server Error: " + error.message});
  })
  }

  function fetchDeliveryDates(deliverySeasonId) {
    fetch(BASEAPI + "/api/DeliveryDate/" + deliverySeasonId,{
      headers: {
        Authorization: 'Bearer ' + userState.token
      }
    })
    .then(handleErrors)
    .then(res => res.json())
    .then((result) => {
      dateDispatch({type: 'INIT', deliveryDates: result})    
    })
    .catch(function(error){
      console.log(error);
      dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Delivery Dates", errorMessage:"Server Error: " + error.message});
  })
  }

  function fetchDefaultSeason() {
    var defaultSeason = localStorage.getItem("defaultSeason");

    if (defaultSeason) {
      var seasonId = parseInt(defaultSeason);
      dispatch({type: 'SELECT_SEASON', deliverySeasonId: seasonId})
      fetchDepots(seasonId);
      fetchDeliveryDates(seasonId);
    }
  }

  function addNewSeason() {
    var name = window.prompt("Enter name of new Season:");

    if (name === null || name === "")
        return;

    fetch(BASEAPI + "/api/DeliverySeason", {
      method: "POST",
      body: "\"" + name + "\"",
      headers: {
          'Content-Type': 'application/json',
      }
    })
    .then(handleErrors)
    .then(res => res.json())
    .then((result) => {
      dispatch({type: "ADD_SEASON", deliverySeason: result});
    })
    .catch(function(error){
      console.log(error);
      dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Delivery Season", errorMessage:"Server Error: " + error.message});
  })
  }

  function getToken() {
    const tokenString = sessionStorage.getItem('token');
    
    if (tokenString)
    {
      userDispatch({type: "SET_TOKEN", token: tokenString});
    }
  }

  function getUser() {
    const userString = sessionStorage.getItem('user');

    if (userString) {
      var action = JSON.parse(userString);
      userDispatch({type: "INIT", user: action.user, userId: action.userId, canRoute: action.canRoute, externalLogin: action.externalLogin, userDepots: action.userDepots});
    }
  }

  useEffect(() => {   
    getUser();
    getToken(); 
    fetchDeliverySeasons();
    fetchDefaultSeason();
  }, []);
    
  function selectDeliverySeason(event) {
    console.log("selected delivery id: " + event.target.value)
    dispatch({type: 'SELECT_SEASON', deliverySeasonId: event.target.value})
    fetchDepots(event.target.value);
    fetchDeliveryDates(event.target.value);
  }

  return (
    <div>
        {userState.user == null ? 
          <Login />
        : <>{appState.selectedDeliverySeasonId ?
        <div>
          {serverErrorState.errorMessage && 
            <ErrorToast />
          }
          <AppLayout />
        </div>
        :
        <Container>
          <Row className="justify-content-center">
            <Col md="auto">
              <h1>Welcome to Delivery Router</h1>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="auto" align="center">
            {appState.deliverySeasons ? 
            <Card body inverse color="primary" className="text-center seasonSelector">
              <CardTitle>Select Delivery Season</CardTitle>
              <CardBody>
                <div>
                  <select name="deliverySeasons" onChange={selectDeliverySeason}>
                      <option key="-1" value={null}>Please Select...</option>
                    {appState.deliverySeasons.map((ds, index) => {
                      var userDelSeasonIds = userState.userDepots.map(x => x.deliverySeasonId);
                      var userDistinctSeasonIds = userDelSeasonIds.filter(onlyUnique);
                      var userHasSeason = userDistinctSeasonIds.filter(x => x == ds.deliverySeasonId).length > 0;
                      return (
                          userHasSeason ?
                          <option key={index} value={ds.deliverySeasonId}>{ds.name}</option> :
                          null
                      );
                    })
                    }
                  </select>
                </div>
                {/* <div className="mt-5">
                  <Button color="secondary" size="sm" onClick={addNewSeason}><FaPlus /> Add New Season</Button>
                  </div> */}
              </CardBody>
            </Card>
            :
            <div>
              <Spinner color="primary" />
              </div>
            }
            </Col>
          </Row>
          </Container>
        }</>
      }
      </div>
    );
}
