import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { AppStateProvider } from './contexts/AppContext';
import { DepotStateProvider } from './contexts/DepotContext';
import { DeliveryDateStateProvider } from './contexts/DeliveryDateContext';
import { UserStateProvider } from './contexts/UserContext';
import { MapDocketStateProvider } from './contexts/MapDocketContext';
import { ServerErrorStateProvider } from './contexts/ServerErrorContext';
import { SecondPhaseFileStateProvider } from './contexts/SecondPhaseFileContext';
import { APIProvider } from '@vis.gl/react-google-maps';
import { GOOGLE_MAP_API_KEY } from './contexts/constants';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <ServerErrorStateProvider>
      <UserStateProvider>
        <AppStateProvider>
          <DepotStateProvider>
            <DeliveryDateStateProvider>
              <MapDocketStateProvider>
                <SecondPhaseFileStateProvider>
                  <APIProvider apiKey={GOOGLE_MAP_API_KEY}>
                    <App />
                  </APIProvider>
                </SecondPhaseFileStateProvider>
              </MapDocketStateProvider>
            </DeliveryDateStateProvider>
          </DepotStateProvider>
        </AppStateProvider>
      </UserStateProvider>
    </ServerErrorStateProvider>
  </BrowserRouter>,
  rootElement);

registerServiceWorker();

