import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { useDepotState } from "../../contexts/DepotContext";
import { useMapDocketState } from "../../contexts/MapDocketContext";
import { Label, ListGroup, ListGroupItem, Modal, ModalBody, Spinner } from "reactstrap";
import { GOOGLE_MAP_API_KEY, linecolors, triple8Color } from "../../contexts/constants";
import Marker from "./Marker";
import DocketInfo from "./DocketInfo";
import MapInfoWindow from "./MapInfoWindow";
import { useSecondPhaseFileState } from "../../contexts/SecondPhaseFileContext";

export default function GoogleMap({depotId, runs}) {
    const defaultProps = {
        center: {
          lat: -33.867257,
          lng: 151.006897
        },
        zoom: 10
      };

    let [curRuns, setCurRuns] = useState();
    let [directions, setDirections] = useState([]);
    let [routes, setRoutes] = useState([]);
    let [markers, setMarkers] = useState([]);
    let [currentMarker, setCurrentMarker] = useState();

    let [showRoutes, setShowRoutes] = useState(false);
      
    let [depotState, depotDispatch] = useDepotState();
    let [mapDocketState, mapDocketDispatch] = useMapDocketState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();

    let [myMap, setMyMap] = useState();
    let [myMaps, setMyMaps] = useState();

    var canToggle = (showRoutes && directions.length > 0) || (!showRoutes && markers.length > 0);

    var depot = depotState.depots.find(x => x.depotId === depotId);

    var depotLocation = {
        lat: depot.address.latitude,
        lng: depot.address.longitude
    };

    useEffect(() => {
        buildMarkers();
        if (showRoutes) {
            buildRoutes(myMap, myMaps);
        }
    }, [curRuns, showRoutes])

    useEffect(() => {
        setCurRuns(runs);
    }, [runs]);

    function getMapBounds(map, maps, markers) {
        const bounds = new maps.LatLngBounds();

        markers.forEach((marker) => {
          bounds.extend(new maps.LatLng(
            marker.point.lat,
            marker.point.lng,
          ));
        });
        return bounds;
    }

    function apiIsLoaded(map, maps, markers) {
        var bounds = getMapBounds(map, maps, markers);
        // Fit map to bounds
        map.fitBounds(bounds);
        setMyMap(map);
        setMyMaps(maps);
    }

    function buildMarkers() {
        var newMarkers = [];

        if (curRuns && curRuns.length > 0) {
            var alreadyMarked = [];

            for(var i = 0; i < curRuns.length; i++) {
                var r = curRuns[i];

                for(var j = 0; j < r.dockets.length; j++)
                {                
                    var docket = r.dockets[j];

                    // eslint-disable-next-line no-loop-func
                    var alreadyAdded = alreadyMarked.find(x => x === docket.docketId);

                    if (!alreadyAdded) {
                        if (curRuns[i].dockets[j].address) {
                            var sameAddresses = grabSameAddressDockets(curRuns[i].dockets, curRuns[i].dockets[j]);

                            for(var s = 0; s < sameAddresses.length; s++) {
                                alreadyMarked.push(sameAddresses[s].docketId);
                            }

                            var label = r.runNumber + "-" + (r.dockets[j].sequenceNumber ? r.dockets[j].sequenceNumber : "#");
                            var key = r.runNumber + "-" + (r.dockets[j].sequenceNumber ? r.dockets[j].sequenceNumber : generateNoSequenceId([docket].concat(sameAddresses)));
                        
                            var blurb = []
                            blurb.push(getInfoBlurbForDocket(docket, sameAddresses));

                            var point = {
                                point: {lat: parseFloat(docket.address.latitude), lng: parseFloat(docket.address.longitude)},
                                key: key,
                                label: label,
                                runNo: r.runNumber,
                                infoMessage: blurb,
                                dockets: [docket].concat(sameAddresses),
                                show: false
                            };

                            newMarkers = newMarkers.concat(point);
                        }
                    }
                }
            }
            setMarkers(newMarkers);
        }
    }

    function buildRoutes(map, maps) {
         if (runs.length > 0) {
            var directionService = maps.DirectionsService();
            var bounds = maps.LatLngBounds();

            var startMarkersCount = markers.length;

            var i = 0;
            var setIntervalId = setInterval(() => {
                var directionsDisplay = maps.DirectionsRenderer({
                    map: map,
                    preserveViewport: true,
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: runs[i].runNumber === 888 ? triple8Color : linecolors[i%20]
                    }
                });

                var depotPt = maps.LatLng(depotLocation.lat, depotLocation.lng);
                var startPt = depotPt;
                var endPt = depotPt;

                var waypoints = [];
                var overflowWaypoints = [];
                var alreadyMarked = [];
                for(var j = 0; j < runs[i].dockets.length; j++)
                {
                    var alreadyAdded = alreadyMarked.find(x => x === runs[i].dockets[j].docketId);

                    if (!alreadyAdded) {

                        if (runs[i].dockets[j].address) {
                            var sameAddresses = grabSameAddressDockets(runs[i].dockets, runs[i].dockets[j]);

                            for(var s = 0; s < sameAddresses.length; s++) {
                                alreadyMarked.push(sameAddresses[s].docketId);
                            }

                            var loc = maps.LatLng(runs[i].dockets[j].address.latitude, runs[i].dockets[j].address.longitude);
                            var wayPt = {
                                location: loc,
                                stopover: false
                            }
                            if (j < 23)
                                waypoints.push(wayPt);
                            else
                            {
                                if (overflowWaypoints.length === 0)
                                {
                                    overflowWaypoints.push(waypoints[waypoints.length -1]); //start with the last waypoint
                                }
                                overflowWaypoints.push(wayPt);
                            }
                            
                            // if (startMarkersCount == 0) {                            
                            //     var marker = addMarker(runs[i].runNumber === 888 ? triple8Color : linecolors[i%20], runs[i].runNumber + "-" + (runs[i].dockets[j].sequenceNumber ? runs[i].dockets[j].sequenceNumber : "#"), loc, runs[i].dockets[j], sameAddresses);
                            //     markers = markers.concat(marker);
                            // }
                            bounds.extend(loc);
                        }
                    }
                }

                if (waypoints.length > 0)
                {
                    if (showRoutes) {
                        calculateAndDisplayRoute(map, directionService, directionsDisplay, startPt, endPt, waypoints, bounds);

                        directions = directions.concat(directionsDisplay);

                        if (overflowWaypoints.length > 0) {
                            var direcationsDisplay2 = new window.google.maps.DirectionsRenderer({
                                map: map,
                                preserveViewport: true,
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: runs[i].runNumber === 888 ? triple8Color : linecolors[i%20],
                                }
                            });

                            calculateAndDisplayRoute(map, directionService, direcationsDisplay2, startPt, endPt, overflowWaypoints, bounds);
                        }
                    }
                    else {
                        map.fitBounds(bounds);
                    }
                }

                if (++i === runs.length) {
                    console.log("end of create")
                    if (startMarkersCount !== markers.length) {
                        setMarkers(markers);
                    }
                    if (directions.length > 0 ){
                        setDirections(directions);
                    }
                    clearInterval(setIntervalId);
                };
            }, showRoutes ? 500 : 10);
        }
    }

    function calculateAndDisplayRoute (map, directionService, directionsDisplay, startPoint, endPoint, waypoints, bounds) {
        var route = directionService.route({
            origin: startPoint,
            destination: endPoint,
            waypoints: waypoints,
            travelMode: 'DRIVING'
        }, function(response, status) {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directionsDisplay.setDirections(response);
              bounds.union(response.routes[0].bounds);
              map.fitBounds(bounds);
            } else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                setTimeout(() => calculateAndDisplayRoute(directionService, directionsDisplay, startPoint, endPoint, waypoints, bounds), 2000)
            } 
            else {
              console.log('Error setting route: ' + status);
              console.log(response);
            }
          });

          routes.push(route);
    }

    function grabSameAddressDockets(dockets, docket) {
        var sameAddressDockets = dockets.reduce((result, value) => {
            if (value.docketId !== docket.docketId && docket.address.latitude === value.address.latitude && docket.address.longitude === value.address.longitude) {
                result.push(value);
            }
                
            return result;
        }, []);

        return sameAddressDockets;
    }

    function getInfoBlurbForDocket(docket, sameAddresses) {
        return (
            <ListGroup>
                <ListGroupItem><DocketInfo docket={docket} /></ListGroupItem>
                {sameAddresses && sameAddresses.map((sameAddress, index) => {
                    return (<ListGroupItem key={index}><DocketInfo docket={sameAddress} /></ListGroupItem>)
                })}
            </ListGroup>)
    }

    function toggleShowRoutes() {
        setShowRoutes(!showRoutes);
    }

    function onChildClick(key) {
        let newMarkers = [...markers];
        var index = newMarkers.findIndex(x => x.key === key);
        var marker = newMarkers[index];
        marker.show = true;
        setCurrentMarker(marker);
        setMarkers(newMarkers);
    }

    function onWindowClose(key) {
        let newMarkers = [...markers];        
        var index = newMarkers.findIndex(x => x.key === key);
        if (index >= 0) {
        var marker = newMarkers[index];
        marker.show = false;
        }
        setCurrentMarker(null);
        setMarkers(newMarkers);
    }

    function onRunsUpdated(runs) {
        let newRuns = [...curRuns];
        runs.forEach(run => {
            var index = newRuns.findIndex(x => x.runId === run.runId);
            newRuns[index] = run;
        });
        
        setCurRuns(newRuns);
        if (currentMarker) {
            var newCurrentMarker = {...currentMarker};

            var oldDocket = newCurrentMarker.dockets[0];
            var runId = 0;
            runs.forEach(run => {
                var dckt = run.dockets.find(x => x.docketId === oldDocket.docketId);
                if (dckt) {
                    runId = dckt.runId;
                }
            })
            var run = newRuns.find(x => x.runId === runId);
            var docket = run.dockets.find(x => x.docketId === oldDocket.docketId);
            var sameAddresses = grabSameAddressDockets(run.dockets, docket);

            var blurb = []
            blurb.push(getInfoBlurbForDocket(docket, sameAddresses));

            var newKey = run.runNumber + '-' + docket.sequenceNumber;
            newCurrentMarker.label = newKey;
            newCurrentMarker.infoMessage = blurb;
            newCurrentMarker.runNo = run.runNumber;
            
            newCurrentMarker.dockets = [docket].concat(sameAddresses);
            setCurrentMarker(newCurrentMarker);
        }

        if (secondPhaseFileState.selectedImportFileId) {
            runs.forEach(run => {
                dispatchSecondPhaseFile({type: "UPDATE_RUN", run: run});
            });
        }
    }

    function onSequenceUpdated(docketId, sequenceNumbers) {
        if (currentMarker && currentMarker.dockets[0].docketId === docketId) {
            var newCurrentMarker = {...currentMarker};
            newCurrentMarker.dockets[0].sequenceNumber = sequenceNumbers[0];
            
            var docket = newCurrentMarker.dockets[0];
            var run = curRuns.find(x => x.runId === newCurrentMarker.dockets[0].runId);
            var sameAddresses = grabSameAddressDockets(run.dockets, docket);
            
            var cntr = 1;
            sameAddresses.forEach(x => {
                var docket = newCurrentMarker.dockets.find(y => y.docketId === x.docketId);
                docket.sequenceNumber = sequenceNumbers[cntr];
                x.sequenceNumber = sequenceNumbers[cntr];
                cntr++;
            })

            var blurb = []
            blurb.push(getInfoBlurbForDocket(docket, sameAddresses));

            var newKey = newCurrentMarker.key.split('-')[0] + '-' + sequenceNumbers[0];
            newCurrentMarker.label = newKey;
            newCurrentMarker.infoMessage = blurb;
            
            setCurrentMarker(newCurrentMarker);
        }
    }

    function getRunIndex(runNo) {
        var index = runs.findIndex(r => r.runNumber === runNo);

        return index;
    }

    function generateKey(marker) {
        if (marker.key.includes("#"))
            return marker.key.split('-')[0] + '-' + generateNoSequenceId(marker.dockets);
        else 
            return marker.key;
    }

    function generateNoSequenceId(dockets) {
        return dockets.reduce((ids, cur) => {
            return ids.concat(cur.docketId + "-");
        },"#")
    }

    return <>
        {/* <Label for="showRoutes">Show Routes <input id="showRoutes" type="checkbox" checked={showRoutes} onChange={toggleShowRoutes} disabled={!canToggle} /></Label> {!canToggle && <Spinner />} */}
        <div style={{width: '100%', height: '600px'}}>
        <GoogleMapReact key={GOOGLE_MAP_API_KEY} options={{gestureHandling: 'cooperative'}} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom} onChildClick={onChildClick} yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, markers)}>
            {markers.map((marker) =>{
                return <Marker key={marker.key} color={marker.runNo === 888 ? triple8Color : linecolors[getRunIndex(marker.runNo)%20]} label={marker.label} dockets={marker.dockets} runs={curRuns} lat={marker.point.lat} lng={marker.point.lng} infoMessage={marker.infoMessage} show={marker.show} onWindowClose={onWindowClose} />
            })}
        </GoogleMapReact>
        {currentMarker && 
        <Modal isOpen={true}>
            <ModalBody>
                {currentMarker && 
                    <MapInfoWindow markerKey={currentMarker.key} label={currentMarker.label} infoMessage={currentMarker.infoMessage} dockets={currentMarker.dockets} runs={curRuns} closeWindow={onWindowClose} color={currentMarker.runNo === 888 ? triple8Color : linecolors[getRunIndex(currentMarker.runNo)%20]} runsUpdated={onRunsUpdated}  sequenceUpdated={onSequenceUpdated} />
                }
            </ModalBody>
        </Modal>
        }
        </div>
    </>
}