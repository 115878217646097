export const BASEAPI = "."; //"https://localhost:44399";

export const GOOGLE_MAP_API_KEY = 'AIzaSyC-t7gH60Q2TCBn8DsZdm_d7eJt0nr3aUg';

export const DEPOTS = [
    { 
        depotId: 1, 
        name: "Sydney", 
        truckTypes: [{truckTypeId: 1, depotId: 1, vehicleCount: 5, maxBoxCount: 100, maxWeight: 525.5, description: 'Hertz Pantech'}],
        deliveryDates: [{deliveryDateId: 1, deliverySeasonId: 2, date: '2020-11-20', isMorning: null, depotId: 1}]
    },
    { depotId: 2, name: "Gosford", truckTypes: [
        {truckTypeId: 2, depotId: 2, vehicleCount: 5, maxBoxCount: 100, maxWeight: 525.5, description: 'Hertz Pantech'}, 
        {truckTypeId: 3, depotId: 2, vehicleCount: 2, maxBoxCount: 10, maxWeight: 65, description: 'Van'}]},
    { depotId: 3, name: "Newcastle"},
    { depotId: 4, name: "Singleton"},
    { depotId: 5, name: "Melbourne"}
   ];

export const DELIVERYSEASONS = [{deliverySeasonId: 1, name: 2019}, {deliverySeasonId: 2, name: 2020}];

export const linecolors = ['#006064',
'#a52714',
'#795548',
'#55b342',
'#f9a825',
'#0096a7',
'#afb42b',
'#9b27b0',
'#ffcc00',
'#01589b',
'#7cb342',
'#1a227e',
'#e65100',
'#c2185c',
'#0289d1',
'#683ab7',
'#00e6e6',
'#ff99bb',
'#bfbfbf',
'#006600'
];

export const triple8Color = '#010101';