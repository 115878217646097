import React, { createContext, useContext, useReducer } from "react";

let DeliveryDateContext = createContext();

export function useDeliveryDateState() {
    return useContext(DeliveryDateContext);
}

const deliveryDateStateReducer = (state, action) => {
    switch (action.type) {
        case "INIT": {
            return {...state, deliveryDates: action.deliveryDates};
        }
        case "ADD_DATE": {
            return {...state, deliveryDates: state.deliveryDates.concat(action.deliveryDate)};
        }
        case "REMOVE_DATE": {
            return {...state, deliveryDates: state.deliveryDates.filter(x => x.deliveryDateId !== action.deliveryDateId) };
        }
        case "ADD_RUNS_TO_DATE": {
            var delDate = state.deliveryDates.find(x => x.deliveryDateId === action.deliveryDateId);
            if (delDate.runs && delDate.runs.length > 0) {
                delDate.runs = delDate.runs.concat(action.runs);
            } else {
                delDate.runs = action.runs;
            }

            //loop through runs and remove any from other dates
            action.runs.forEach(run => {
                var dd = state.deliveryDates.find(x => x.deliveryDateId !== action.deliveryDateId && x.runs && x.runs.some(y => y.runId === run.runId));
                if (dd && dd.runs) {
                    dd.runs = dd.runs.filter(x => x.runId !== run.runId);
                }
            });

            return {...state, state}
        }
        case "MOVE_RUNS_TO_ANOTHER_DATE": {
            return {...state, state}
        }
        default:
            return state;
    }
};

const initialState = {
    deliveryDates: [],
};

export function DeliveryDateStateProvider({children}) {
    let deliveryDateState = useReducer(deliveryDateStateReducer, initialState);

    return <DeliveryDateContext.Provider value={deliveryDateState}>{children}</DeliveryDateContext.Provider>
}