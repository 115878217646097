import React from "react";
import { Button } from "reactstrap";
import {MdAddBox} from "react-icons/md";
import "./AddDepotTile.css";
import { useHistory } from "react-router-dom";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import { useAppState } from "../contexts/AppContext";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function AddDepotTile() {
    let history = useHistory();
    let [state, dispatch] = useDepotState();
    let [appState, appDispatch] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();
    
    function handleClick(isMda) {
        var name = window.prompt("Enter name of new Depot:");

        if (name === null || name === "")
            return;

        //submit to api and on success then dispatch
        
        fetch(BASEAPI + "/api/Depot", {
            method: 'POST',
            body: JSON.stringify({
                deliverySeasonId: parseInt(appState.selectedDeliverySeasonId),
                name: name,
                isMda: isMda
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            dispatch({type: "ADD_DEPOT", depot: result});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Creating Depot", errorMessage:"Server Error: " + error.message});
        });

        //state in this function is not updated, need to refetch before redirecting
        //var depotId = state.depots.find(x => x.name === name).depotId;
        //history.push("/depot/" + depotId);
    }

    return (
        <div className="addDepot">
            <div><Button className="btn addDepotBtn" color="danger" onClick={() => handleClick(false)}>Add Depot<MdAddBox /></Button></div>
            <div><Button className="btn addMDADepotBtn" onClick={() => handleClick(true)}>Add MDA Depot<MdAddBox /></Button></div>
        </div>
    )
}