import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import { useAppState } from "../contexts/AppContext";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import "./ImportDocket.css";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { FaTrash } from "react-icons/fa";
import ImportedFileSecondPhaseRuns from "./ImportedFileSecondPhaseRuns";
import { useSecondPhaseFileState } from "../contexts/SecondPhaseFileContext";
import { taskStatusEnum, taskStatusLabel } from "./Enums";
import { useUserState } from "../contexts/UserContext";

export default function ImportedFileList() {
    let [appState, appDispatch] = useAppState();
    let [userState, userDispatch] = useUserState();
    let [fileList, setFileList] = useState();
    let [listLoading, setListLoading] = useState(false);
    let [errorState, dispatchServerError] = useServerErrorState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();

    let depotId = parseInt(appState.selectedDepotId);
    //let secondPhaseFiles = fileList && fileList.filter(x => x.secondPhase);
    // let anySecondPhase = secondPhaseFiles && secondPhaseFiles.length > 0;
    // let latestSecondPhase = anySecondPhase ? findLatestSecondPhase(secondPhaseFiles) : null;
    let anySecondPhase = secondPhaseFileState.selectedImportFileId !== null;
    let latestSecondPhase = anySecondPhase ? secondPhaseFileState.secondPhaseFile : null;

    function removeImportFile(importFileId) {
        if (window.confirm("Are you sure you want to remove file?")) {
            fetch(BASEAPI + "/api/ImportDockets/" + importFileId, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                if (result.success) {

                    if (result.taskRunningWarning) {
                        window.alert("File Removed that had running route task.  Please Contact IT, as the task will need to be manually stopped.");
                    }

                    var filteredFileList = fileList.filter(x => x.importFileId !== importFileId);
                    setFileList(filteredFileList);
                }
            })
        }
    }

    function findLatestSecondPhase(secondPhaseFiles) {
        var latest = null;
        for(var i = 0; i < secondPhaseFiles.length; i++)
        {
            if (!latest || latest.importedAt < secondPhaseFiles[i].importedAt) {
                latest = secondPhaseFiles[i];
            }
        }

        return latest;
    }

    useEffect(() => {
        setFileList(null);
        setListLoading(true);
        fetch(BASEAPI + "/api/ImportDockets/?depotId=" + depotId,
        {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setFileList(result);
            var secondPhaseFiles = result && result.filter(x => x.secondPhase);
            var hasAnySecondPhase = secondPhaseFiles && secondPhaseFiles.length > 0;
            var mostRecentSecondPhase = hasAnySecondPhase ? findLatestSecondPhase(secondPhaseFiles) : null;
            if (mostRecentSecondPhase) {
                dispatchSecondPhaseFile({type: "UPDATE_FILE", selectedImportFileId: mostRecentSecondPhase.importFileId, secondPhaseFile: mostRecentSecondPhase})
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Files", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setListLoading(false);
        })
    }, [depotId])

    return (
        <div>
            {!anySecondPhase &&
            <div>
                <h3>List of Imported Files</h3>
                <Container>
                    <Row className="importHeader">
                        <Col>Filename</Col>
                        <Col>Imported At</Col>
                        <Col>Number of Dockets</Col>
                        <Col>Imported By</Col>
                        <Col>&nbsp;</Col>
                    </Row>
                    {listLoading && <Spinner color="success" />}
                    {fileList && fileList.map((importedFile, index) => {
                        var anyDockets = importedFile.docketIds.length > 0;
                        var anyRuns = importedFile.runIds.length > 0;

                        return (
                            <Row key={index}>
                                <Col>{importedFile.fileName}</Col>
                                <Col>{format(new Date(importedFile.importedAt), "dd/MM/yyyy hh:mm:ss")}</Col>
                                <Col>{importedFile.docketCount}</Col>
                                <Col>{importedFile.userName}</Col>
                                <Col lg="auto">
                                    {anyDockets && !anyRuns && importedFile.taskId && importedFile.taskStatus == taskStatusEnum.Failed && <Button color="link" tag={Link} to={"/failedRoute/" + importedFile.importFileId}>{taskStatusLabel(importedFile.taskStatus)}</Button>}
                                    {anyDockets && !anyRuns && (!importedFile.taskId || importedFile.taskStatus != taskStatusEnum.Failed) && <Button color="link" tag={Link} to={"/engine/" + importedFile.importFileId}>{importedFile.taskId ? taskStatusLabel(importedFile.taskStatus) : "Route"}</Button>}
                                    {/* {anyDockets && anyRuns && <Button color="link">Re-Route</Button>} */}
                                    <Button color="link" tag={Link} to={"/importFileRuns/" + importedFile.importFileId} disabled={!anyRuns}>Runs</Button>
                                    {/* <Button color="link" disabled={!anyRuns}>Map</Button> */}
                                    <Button size="sm" color="danger" onClick={() => removeImportFile(importedFile.importFileId)} ><FaTrash /></Button>
                                </Col>
                            </Row>
                        )
                    })}
                </Container>
            </div>
            }
            {anySecondPhase && latestSecondPhase &&
            <div>
                <div>1st cuts are hidden as there is a second phase file. Showing Second Phase Files</div>
                <h3>Latest Second Phase File Imported By {latestSecondPhase.userName} at {format(new Date(latestSecondPhase.importedAt), "dd/MM/yyyy hh:mm:ss")}</h3>
                
                <ImportedFileSecondPhaseRuns deliveryDates={latestSecondPhase.deliveryDates} importFileId={latestSecondPhase.importFileId} />
            </div>
            }
        </div>
    )
}