import React, { useState } from "react";
import { FormGroup, Label, Button, Col, Row } from "reactstrap";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useUserState } from "../contexts/UserContext";

export default function DepotAddressEdit({depotId}) {
    let [depotState, depotDispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    let depot = depotState.depots.find(x => x.depotId === depotId);
    let addressId = depot.addressId;

    let [addressLine1, setAddressLine1] = useState(depot.address ? depot.address.addressLine1 : null);
    let [suburb, setSuburb] = useState(depot.address ? depot.address.city : null);
    let [postCode, setPostCode] = useState(depot.address ? depot.address.postCode : null);
    let [state, setState] = useState(depot.address ? depot.address.state : null);
    let [latitude, setLatitude] = useState(depot.address ? depot.address.latitude : null);
    let [longitude, setLongitude] = useState(depot.address ? depot.address.longitude : null);

    function addAddress() {
        //add address
        fetch(BASEAPI + "/api/Address", 
        {
            method: "POST",
            body: JSON.stringify({
                addressLine1: addressLine1,
                suburb: suburb,
                state: state,
                postCode: postCode
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token

            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {            
            //then add to depot
            var addressId = result.addressId;
            
            fetch(BASEAPI + "/api/Depot/AddAddressToDepot", {
                method: "POST",
                body: JSON.stringify({
                    depotId: depotId,
                    addressId: addressId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(res => res.json())
            .then((depotResult) => {
                if (depotResult) {
                //dispatch to add to depot state
                depotDispatch({type: "ADD_DEPOT_ADDRESS", depotId: depotId, addressId: addressId, address: result});
                }
            })

            
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Address", errorMessage:"Server Error: " + error.message});
        })
        
    }    

    function updateAddress() {
        console.log("updating address");

        fetch(BASEAPI + "/api/Address", {
            method: "PUT",
            body: JSON.stringify({
                addressId: addressId,
                addressLine1: addressLine1,
                suburb: suburb,
                state: state,
                postCode: postCode,
                latitude: latitude,
                longitude: longitude
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            depotDispatch({type: "UPDATE_DEPOT_ADDRESS", depotId: depotId, addressId: addressId, address: result});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Address", errorMessage:"Server Error: " + error.message});
        })
    }

    function geocodeAddress() {
        fetch(BASEAPI + "/api/address/GeocodeAddress/" + addressId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            depotDispatch({type: "UPDATE_DEPOT_ADDRESS", depotId: depotId, addressId: addressId, address: result});
            setLatitude(result.latitude);
            setLongitude(result.longitude);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Address", errorMessage:"Server Error: " + error.message});
        })
    }

    return (
        <div>
            <FormGroup as={Row}>
                <Label column sm="2" for="addressLine1">Street</Label>
                <Col sm="10">
                    <input type="text" id="addressLine1" value={addressLine1} onChange={e => setAddressLine1(e.target.value)}/>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="city">City</Label>
                <Col sm="10">
                    <input type="text" id="city" value={suburb} onChange={e => setSuburb(e.target.value)}/>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="state">State</Label>
                <Col sm="10">
                    <select id="state" value={state} onChange={e => setState(e.target.value)}>
                        <option>Please Select...</option>
                        <option value="NSW">New South Wales</option>
                        <option value="VIC">Victoria</option>
                        <option value="QLD">Queensland</option>
                        <option value="SA">South Australia</option>
                        <option value="WA">Western Australia</option>
                        <option value="TAS">Tasmania</option>
                        <option value="ACT">ACT</option>
                        <option value="NT">Northern Territory</option>
                    </select>
                </Col>
            </FormGroup>
            <FormGroup as={Row}>
                <Label column sm="2" for="postCode">Post Code</Label>
                <Col sm="10">
                    <input type="text" id="postCode" value={postCode} onChange={e => setPostCode(e.target.value)} />
                </Col>
            </FormGroup>
            {addressId > 0 &&
                <div>
                    <div><Button size="sm" color="info" onClick={geocodeAddress}><FaMapMarkerAlt /> Geocode Address</Button></div>
                    <FormGroup as={Row}>
                        <Label column sm="2" for="latitude">Latitude</Label>
                        <Col sm="10">
                            <input type="text" id="latitude" value={latitude} onChange={e => setLatitude(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                        <Label column sm="2" for="longitude">Latitude</Label>
                        <Col sm="10">
                            <input type="text" id="longitude" value={longitude} onChange={e => setLongitude(e.target.value)} />
                        </Col>
                    </FormGroup>
                </div>
            }
            {addressId && addressId > 0 ?
                <Button color="success" onClick={updateAddress}>Update Address</Button>
            :
                <Button color="primary" onClick={addAddress}>Add Address</Button>
            }

        </div>
    )
}