import React from "react";
import { Button } from "reactstrap";
import TruckTypeTile from "./TruckTypeTile";
import { FaTruck } from "react-icons/fa";
import "./TruckTypeList.css";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function TruckTypeList({depot}) {
    let [state, dispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

function handleClick() {
    fetch(BASEAPI + "/api/Depot/" + depot.depotId + "/TruckType", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userState.token
        }
    })
    .then(handleErrors)
    .then(res => res.json())
    .then((result) =>{
        dispatch({type: 'ADD_TRUCKTYPE', depotId: depot.depotId, truckType: result});
    })
    .catch(function(error){
        console.log(error);
        dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Truck Type", errorMessage:"Server Error: " + error.message});
    })
}

    return (
        <div className="truckTypeGrid">
            {depot.truckTypes && depot.truckTypes.map((truckType, index) => {
                return (
                    <TruckTypeTile key={index} truckType={truckType} />
                );
            })}
            <div className="newTruckType">
                <Button className="btn" color="success" onClick={handleClick}>Add Truck Type <FaTruck /></Button>
            </div>
        </div>
    );
}