import React, { useState } from "react"
import { useDepotState } from "../contexts/DepotContext"
import AddDeliveryDateTile from "./AddDeliveryDateTile"
import DeliveryDateTile from "./DeliveryDateTile"

export default function EditDeliveryDateList({depot}) {

    return (
        <div>
            <div className="grid-container">
                {depot.deliveryDates && depot.deliveryDates.map((deliveryDate, index) => {
                    return (
                        <DeliveryDateTile deliveryDate={deliveryDate} />
                    );
                })}
                <AddDeliveryDateTile depotId={depot.depotId} />
            </div>
        </div>
    )
}