import React from "react";

export default function DocketInfo({docket}) {
    return (<>
        <b>Seq:</b> {docket.sequenceNumber}<br />
        <b>Member No:</b> {docket.memberNumber}<br />
        <b>Order No:</b> {docket.orderNumber}<br />
        <b>Box Count:</b> {docket.boxCount}<br />
        <b>Order Weight:</b> {docket.orderWeight}<br />
        <b>Address:</b> {docket.address.addressLine1}, {docket.address.city}  {docket.address.state} {docket.address.postCode}
        </>
    )
}