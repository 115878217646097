import React, { useState } from "react";
import { compareAsc, format } from "date-fns";
import { Label, Spinner, Button, FormGroup, Form, Input } from "reactstrap";
import RunsList from "./RunsList";
import { handleErrors, pad } from "./Helpers";
import { BASEAPI } from "../contexts/constants";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { FaMap, FaTruck } from "react-icons/fa";
import { useSecondPhaseFileState } from "../contexts/SecondPhaseFileContext";
import { useUserState } from "../contexts/UserContext";

export default function ImportedFileSecondPhaseRuns(params) {
    let [currentDateId, setCurrentDateId] = useState();
    let [deliveryDates, setDeliveryDates] = useState(params.deliveryDates);
    let [importFileId, setImportFileId] = useState(params.importFileId);
    let [processing, setProcessing] = useState(false);
    let [userState, userDispatch] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();
    
    let [optimiseRunning, setOptimiseRunning] = useState(false);
    let [showOptimiseOptions, setShowOptimiseOptions] = useState(false);
    let [optimiseAll, setOptimiseAll] = useState(true);
    let [optimiseRunList, setOptimiseRunList] = useState([]);

    let currentDeliveryDate = currentDateId && secondPhaseFileState && secondPhaseFileState.secondPhaseFile && secondPhaseFileState.secondPhaseFile.deliveryDates ? secondPhaseFileState.secondPhaseFile.deliveryDates.find(x => x.deliveryDateId == currentDateId) : null;

    function changeDate(event) {
        if (event.target.value < 0) 
            setCurrentDateId(null)
        else
            setCurrentDateId(event.target.value);
    }

    function addTruck() {
        setProcessing(true);

        fetch(BASEAPI + "/api/run/AddRunToSecondPhase/" + importFileId + "/" + currentDateId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            var updatedRuns = currentDeliveryDate.runs.concat(result).sort((a, b) => {
                if (a.runNumber < b.runNumber)
                    return -1;
                else if (a.runNumber == b.runNumber)
                    return 0;
                else
                    return 1;
            });
            currentDeliveryDate.runs = updatedRuns;
            //setDeliveryDates(deliveryDates);
            dispatchSecondPhaseFile({type: "UPDATE_DELIVERYDATES", deliveryDates: deliveryDates})
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Run", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    function showOptimise() {
        setShowOptimiseOptions(true);
    }

    function toggleOptimiseRun(runId) {
        var index = optimiseRunList.findIndex(x => x == runId);

        if (index > -1) {
            optimiseRunList.splice(index, 1);
        } else {
            optimiseRunList = optimiseRunList.concat(runId);
        }

        setOptimiseRunList(optimiseRunList);
    }

    function optimiseRuns() {
        setOptimiseRunning(true);

        fetch(BASEAPI + "/api/importDockets/OptimiseSequence", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            },
            body: JSON.stringify({
                importFileId: importFileId,
                dateToOptimise: parseInt(currentDateId),
                optimiseAll: optimiseAll,
                runIds: optimiseRunList
            })
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            //loop through runs and update them
            for(var i = 0; i < result.length; i++)
                dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result[i]});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Optimising Runs", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setOptimiseRunning(false);
        })
    }

    return (
        <div>
            <div>
                <Label for="deliveryDates">Delivery Dates</Label>
                <select id="deliveryDates" onChange={changeDate} value={currentDateId}>
                    <option value="-1">Select Date...</option>
                {deliveryDates && deliveryDates.sort((a,b) => compareAsc(new Date(a.date),new Date(b.date))).map((dd, index) => {
                    var formattedDate = format(new Date(dd.date), "dd/MM/yyyy");

                    return (
                        <option key={index} value={dd.deliveryDateId}>{formattedDate}</option>
                    )
                })}
                </select>
            </div>
            {currentDateId && 
            <div>
                <div>
                    <Button color="primary" onClick={addTruck}>Add Run <FaTruck /></Button>
                    {processing && <Spinner color="primary" />}
                </div>
                {importFileId > 0 && <div>
                    <Button color="warning" onClick={showOptimise}><FaMap /> Optimise Runs</Button>
                    {showOptimiseOptions && 
                     <div className="formGroup">
                        <div className="groupTitle">Optimise Runs</div>
                        <Form>
                            <FormGroup tag="fieldset">
                                <FormGroup check>
                                    <Input type="radio" name="optimiseAll" value={true} checked={optimiseAll === true} onChange={e => setOptimiseAll(true)} />{' '}<Label check>All Runs</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" name="optimiseAll" value={false} checked={optimiseAll === false} onChange={e => setOptimiseAll(false)} />{' '}<Label check>Some Runs</Label>
                                </FormGroup>
                            </FormGroup>
                            {!optimiseAll && 
                            <div className="formGroup">
                                <div className="groupTitle">Select Runs to Optimise</div>
                                <FormGroup>
                                {currentDeliveryDate.runs.map((run, index) => {
                                    if (run.runNumber !== 888) {
                                        var selectedIndex = optimiseRunList.findIndex(x => x == run.runId);
                                        var selected = selectedIndex > -1;

                                        return <FormGroup key={index} check inline><Input type="checkbox" defaultChecked={selected} onChange={e => toggleOptimiseRun(run.runId)} /> <Label check>{pad(run.runNumber, 3)}</Label></FormGroup>
                                    }
                                })}
                                </FormGroup>
                            </div>}
                            <Button color="warning" disabled={optimiseRunning} onClick={optimiseRuns}><FaMap /> Run Optimisations</Button>
                        </Form>
                    </div>
                    }
                    {optimiseRunning && <Spinner color="warning" />}
                </div>}
                
                <RunsList runs={currentDeliveryDate.runs} importFileId={importFileId} />
            </div>}
        </div>
    )
}