import React, { useState } from "react"
import { useDepotState } from "../contexts/DepotContext"
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { Row, Col, Container, Badge, Progress, Button, Spinner } from "reactstrap";
import { getDay, lastDayOfWeek, format } from "date-fns";
import "./DeliveryDateCalendar.css";
import RunsList from "./RunsList";

export default function DeliveryDateCalendar({depotId}) {
    let [depotState, depotDispatch] = useDepotState();
    let [deliveryDateState, dateDispatch] = useDeliveryDateState();
    let [selectedDelDateIndex, setSelectedDelDateIndex] = useState(-1);
    //let [selectedDelDate, setSelectedDelDate] = useState();
    

    let depot = depotState.depots.find(x => x.depotId === depotId);
    
    var truckCount = 0;
    if (depot && depot.truckTypes) {
        for (var i = 0; i < depot.truckTypes.length; i++)
        {
            truckCount += depot.truckTypes[i].quantity;
        }
    }
    
    let deliveryDates = deliveryDateState.deliveryDates.filter(x => x.depotId === depotId).sort((a,b) => {
        var sameDate = a.date == b.date;

        if (sameDate) {
            if (a.isMorning === b.isMorning)
                return 0;
            else if (a.isMorning)
                return -1;
            else
                return 1;
        }

        if (a.date < b.date) {
            return -1
        } else {
            return 1;
        }
    });

    let selectedDelDate = selectedDelDateIndex >= 0 ? deliveryDates[selectedDelDateIndex] : null;
    let selectedRuns = selectedDelDate ? selectedDelDate.runs : null;

    let calendarRows = buildCalendar();

    const spinner = document.getElementById('loadingSpinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
        spinner.setAttribute('hidden', true);
    }

    function buildCalendar() {
        var isSameWeek = false;
        var prevDayOfWeek = 1;
        var lastDayInWeek = null;

        var emptyDate = <Col><div className="dateTile emptyDate">Unused Date</div></Col>;

        var rows = [];
        var cols = [];

        for(var i = 0; i < deliveryDates.length;i++) {
            var dd = deliveryDates[i];
            var date = new Date(dd.date);

            if (lastDayInWeek && lastDayInWeek < date) {
                if (cols.length < 7) {
                    addEmptyToEndOfWeek(cols, emptyDate);
                }

                rows.push(<Row>{cols}</Row>)
                cols = [];
                prevDayOfWeek = 1;
            }

            var dayOfWeek = getDay(date);
            if (dayOfWeek == 0)
                dayOfWeek = 6;
            
            var daysBetween = dayOfWeek - prevDayOfWeek;
            if (daysBetween > 1) {
                for(var j = 0; j < daysBetween; j++) {
                    cols.push(emptyDate);
                }
            }

            if (dd.isMorning && (i + 1) < deliveryDates.length) {
                var nextDD = deliveryDates[i+1];
                var nextDate = new Date(nextDD.date);
                if (date.getDate() == nextDate.getDate() && date.getMonth() == nextDate.getMonth() && date.getFullYear() == nextDate.getFullYear())
                {
                    cols.push(<Col>{buildDateTile(dd, date, dd.isMorning, dd.runs ? dd.runs.length : 0)}{buildDateTile(nextDD, nextDate, nextDD.isMorning, nextDD.runs ? nextDD.runs.length : 0)}</Col>)
                    i++;
                }
                else {
                    cols.push(<Col>{buildDateTile(dd, date, dd.isMorning, dd.runs ? dd.runs.length : 0)}</Col>);    
                }
            }
            else {
                cols.push(<Col>{buildDateTile(dd, date, dd.isMorning, dd.runs ? dd.runs.length : 0)}</Col>);
            }

            prevDayOfWeek = dayOfWeek;
            lastDayInWeek = lastDayOfWeek(date, { weekStartsOn: 1});
        }

        if (cols.length > 0)
        {
            if (cols.length < 7)
                addEmptyToEndOfWeek(cols, emptyDate);

            rows.push(<Row>{cols}</Row>)
        }

        return rows;
    }

    function addEmptyToEndOfWeek(cols, emptyDate) {
        var emptyNeeded = 7 - cols.length;
        for (var k = 0; k < emptyNeeded; k++) {
            cols.push(emptyDate);
        }
    }

    function buildDateTile(deliveryDate, date, isMorning, runCount) {
        var badge = isMorning !== null ? (isMorning ? <Badge color="info">Morning</Badge> : <Badge color="warning">Afternoon</Badge>) : null;
        var dateTileClass = isMorning === null ? "dateTile fullDate" : (isMorning ? "dateTile morning" : "dateTile afternoon");

        var activeTile = deliveryDate === selectedDelDate;

        if (activeTile)
            dateTileClass = dateTileClass + " activeTile";

        var runPerc = runCount / truckCount * 100;

        return <div className={dateTileClass} onClick={() => { 
            console.log("setting del date to " + deliveryDate.deliveryDateId);
            console.log(deliveryDate);
            var index = deliveryDates.indexOf(deliveryDate);
            setSelectedDelDateIndex(index);
        }
        }>
            <div>{badge}{format(date, "dd/MM/yyyy")}</div>
            <div>
                <Progress value={runPerc}>{runCount} / {truckCount}</Progress>
            </div>
            {/* <div className="detailsBtn"><Button size="sm" color="primary">Details</Button></div> */}
            </div>
    }

    return (
        <div>
            <Container className="calGrid">
                <Row className="calHeader">
                    <Col>Monday</Col>
                    <Col>Tuesday</Col>
                    <Col>Wednesday</Col>
                    <Col>Thursday</Col>
                    <Col>Friday</Col>
                    <Col>Saturday</Col>
                    <Col>Sunday</Col>
                </Row>
                {calendarRows}
            </Container>
            <div id="loadingSpinner"><Spinner /></div>
            {selectedDelDate && selectedRuns && selectedRuns.length > 0 &&
                <div>
                    <h3>Runs for Selected Delivery Date: {format(new Date(selectedDelDate.date), "dd/MM/yyyy")} {selectedDelDate.isMorning !== null ? (selectedDelDate.isMorning ? 'AM' : 'PM') : ''}</h3>
                    <RunsList runs={selectedRuns} />
                </div>
            } 
        </div>
    )
}


