import React from "react"
import { MdDelete } from "react-icons/md";
import { Button, Badge } from "reactstrap";
import "./DeliveryDateTile.css"
import { BASEAPI } from "../contexts/constants";
import { useDepotState } from "../contexts/DepotContext";
import { format } from "date-fns";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function DeliveryDateTile({deliveryDate}) {
    let [depotState, dispatchDepot] = useDepotState();
    let [deliveryDateState, dispatchDate] = useDeliveryDateState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();

    var formattedDate = format(new Date(deliveryDate.date), "dd/MM/yyyy");

    function deleteDeliveryDate() {
        fetch(BASEAPI + "/api/Depot/RemoveDeliveryDate/" + deliveryDate.deliveryDateId, {
            method: "DELETE",
            headers: {
                  Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            dispatchDepot({type: 'REMOVE_DELIVERYDATE', depotId: deliveryDate.depotId, deliveryDateId: deliveryDate.deliveryDateId})
            dispatchDate({type: 'REMOVE_DATE', deliveryDateId: deliveryDate.deliveryDateId})
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Removing Delivery Date", errorMessage:"Server Error: " + error.message});
        })
    }

    var classes = deliveryDate.isMorning === null ? "configDateTile" : (deliveryDate.isMorning ? "morningDateTile" : "afternoonDateTile");

    return (
        <div className={classes}>
            <div>Delivery Date - {formattedDate} {deliveryDate.isMorning !== null ? (deliveryDate.isMorning ? <Badge color="info">Morning</Badge> : <Badge color="warning">Afternoon</Badge>) : <Badge color="secondary">All Day</Badge>} </div>
            <div><Button className="deleteButton" size="sm" color="danger" onClick={deleteDeliveryDate}><MdDelete /></Button></div>
        </div>
    );
}