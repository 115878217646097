import React from "react";

export default function AddressView({address}) {

    return (
        <div>
            {address && <div>
                <div className="addressViewLine">{address.addressLine1} {address.addressLine2}</div>
                <div className="addressViewLine">{address.city} {address.state} {address.postCode}</div>
                </div>
            }
            {!address && <div>
                No Address Set
                </div>}
        </div>
    )
}