import React, { useState, useEffect} from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Label, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, FormGroup } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import './NavMenu.css';
import { useAppState } from '../contexts/AppContext';
import { BASEAPI } from '../contexts/constants';
import { handleErrors, onlyUnique } from './Helpers';
import { useDepotState } from '../contexts/DepotContext';
import { useServerErrorState } from '../contexts/ServerErrorContext';
import { useDeliveryDateState } from '../contexts/DeliveryDateContext';
import { useUserState } from '../contexts/UserContext';
import { PostJsonWithToken } from './fetch_helpers';
import { GiHamburgerMenu } from "react-icons/gi";
import { el } from 'date-fns/locale';

export function NavMenu() {
  let history = useHistory();
  let [collapsed, setCollapsed] = useState(true);
  let [defaultSeasonId, setDefaultSeasonId] = useState();
  let [appState, appDispatch] = useAppState();
  let [userState, userDispatch] = useUserState();
  let [depotState, depotDispatch] = useDepotState();
  let [deliveryDateState, dateDispatch] = useDeliveryDateState();
  let [errorState, dispatchServerError] = useServerErrorState();

  useEffect(() => {
    fetchDefaultSeason()
  }, [])

  function fetchDefaultSeason() {
    var defaultSeason = localStorage.getItem("defaultSeason");

    console.log("default season: " + defaultSeason);

    if (defaultSeason) {
      setDefaultSeasonId(parseInt(defaultSeason));
    }
  }

    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    function fetchDepots(deliverySeasonId) {
      fetch(BASEAPI + "/api/Depot/?deliverySeasonId=" + deliverySeasonId,
      {
        headers: {
          Authorization: 'Bearer ' + userState.token
        }
      })
      .then(handleErrors)
      .then(res => res.json())
      .then((result) => {
        depotDispatch({type: 'INIT', depots: result})
      })
      .catch(function(error){
        console.log(error);
        dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Depots for Season", errorMessage:"Server Error: " + error.message});
    })
    }
  
    function fetchDeliveryDates(deliverySeasonId) {
      fetch(BASEAPI + "/api/DeliveryDate/" + deliverySeasonId, {
        headers: {
          Authorization: 'Bearer ' + userState.token
        }
      })
      .then(handleErrors)
      .then(res => res.json())
      .then((result) => {
        dateDispatch({type: 'INIT', deliveryDates: result})    
      })
      .catch(function(error){
        console.log(error);
        dispatchServerError({type: "SET_ERROR",errorTitle:"Error Fetching Delivery Dates", errorMessage:"Server Error: " + error.message});
    })
    }

    function changeDeliverySeason(event) {
      console.log("changing delivery season from nav: " + event.target.value)
      // console.log("current default: " + defaultSeasonId)
      // console.log("default === current: " + (defaultSeasonId === parseInt(event.target.value)))
      appDispatch({type: 'SELECT_SEASON', deliverySeasonId: event.target.value})
      fetchDepots(event.target.value);
      fetchDeliveryDates(event.target.value);
    }


    async function logout() {
      const uri = BASEAPI + "/api/account/signout"
  
      var result = await PostJsonWithToken(uri, null, userState.token)
                        .catch(function(error){
                            console.log(error);
                            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Logging Out", errorMessage: error.message});
                        });
  
      if (result) {
        userDispatch({type: 'CLEAR'});
        history.push("/");
      }
    }

    function onDefaultSeasonToggle(event) {
      if (event.target.checked) {
        localStorage.setItem("defaultSeason", appState.selectedDeliverySeasonId);
        setDefaultSeasonId(appState.selectedDeliverySeasonId);
      } else {
        localStorage.removeItem("defaultSeason");
        setDefaultSeasonId(null);
      }
    }


    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <Row>
            {/* <NavbarBrand tag={Link} to="/">DeliveryRouter.Web</NavbarBrand> */}
            <Col lg="auto">
             <NavbarBrand>Delivery Router</NavbarBrand>
            </Col>
            <Col lg="auto">
            <div className="currentUser">
                  as {userState.user}
            </div>
            </Col>
            <Col lg="auto">
            <div className="delSeasonSelector">
              <Label>Delivery Season:
                <select id="deliverySeasonSelector" onChange={changeDeliverySeason} value={appState.selectedDeliverySeasonId}>
                  {appState.deliverySeasons.map((ds, index) => {
                     var userDelSeasonIds = userState.userDepots.map(x => x.deliverySeasonId);
                     var userDistinctSeasonIds = userDelSeasonIds.filter(onlyUnique);
                     var userHasSeason = userDistinctSeasonIds.filter(x => x == ds.deliverySeasonId).length > 0;
                    return (
                      userHasSeason ?
                        <option key={index} value={ds.deliverySeasonId}>{ds.name}</option> : null
                    );
                  })
                  }
                </select>
              </Label>
              <FormGroup check inline>
                <Label check>Default: </Label>
                <Input id="defaultSeason" type="checkbox" checked={defaultSeasonId === parseInt(appState.selectedDeliverySeasonId)} value={defaultSeasonId === parseInt(appState.selectedDeliverySeasonId)} onChange={onDefaultSeasonToggle} />
              </FormGroup>
            </div>
            </Col>
            <Col lg={{ size:"auto", offset: 0}}>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/config">Config</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/route">Route</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/manage">Manage</NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/tasks">Route Tasks</NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/manageUsers">Users</NavLink>

                </NavItem> */}
                 <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <GiHamburgerMenu />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={logout}>
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </ul>
            </Collapse>
            </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    );
}
