import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaPlus, FaFolderPlus } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "./Config.css"
import { BASEAPI } from "../contexts/constants";
import { useDepotState } from "../contexts/DepotContext";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function DepotPostCodes({depot}) {
    let [depotState, dispatch] = useDepotState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [searchText, setSearchText] = useState('');
    let [allPostCodes, setAllPostCodes] = useState();
    let [searchResults, setSearchResult] = useState(null);

    function search(event) {
        setSearchText(event.target.value);

        if (event.target.value.length > 2) {
            var results = allPostCodes.filter(x => x.code.includes(event.target.value));
            setSearchResult(results);
        }
        else {
            setSearchResult(null);
        }
    }

    function getAllPostCodes() {
        fetch(BASEAPI + "/api/Config/GetAllPostCodes", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setAllPostCodes(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Post Codes", errorMessage:"Server Error: " + error.message});
        })
    }

    function addPostCode(postCodeId, allSuburbs) {
        fetch(BASEAPI + "/api/Depot/AssignPostCodesToDepot", {
            method: "POST",
            body: JSON.stringify({
                depotId: depot.depotId,
                postCodes: [{postCodeId: postCodeId, allSuburbs: allSuburbs}]
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            dispatch({type: "ADD_POSTCODES_AND_SUBURBS", depotId: depot.depotId, postCodes: result.postCodes, suburbs: result.suburbs});
            setSearchText('');
            setSearchResult(null);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding PostCode to Depot", errorMessage:"Server Error: " + error.message});
        })
    }

    function deletePostCode(postCodeId) {
        fetch(BASEAPI + "/api/Depot/RemovePostCodeFromDepot", {
            method: "POST",
            body: JSON.stringify({
                depotId: depot.depotId,
                postCodeId: postCodeId
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                dispatch({type: "REMOVE_POSTCODE", depotId: depot.depotId, postCodeId: postCodeId});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Removing PostCode from Depot", errorMessage:"Server Error: " + error.message});
        })
    }

    useEffect(() => {
        getAllPostCodes();
    }, [])

    return (
        <div>
            <div className="suburb-grid">
                {depot.postCodes && depot.postCodes.map((postCode, index) => {
                    return (
                        <div>{postCode.code} <Button size="sm" color="secondary" onClick={() => deletePostCode(postCode.postCodeId)}><MdDelete /></Button></div>
                    )
                })}
            </div>
            <div>
                <input type="search" name="postCodeSearch" id="postCodeSearch" placeholder="Search for a Post Code" onChange={search} value={searchText} />
                <div id="searchResults">
                {searchResults && 
                    searchResults.map((postCode, index) => {
                        return (
                            <div>
                                <div>{postCode.code}</div>
                                <div>
                                    <Button color="primary" size="sm" onClick={() => addPostCode(postCode.postCodeId, false)}><FaPlus /></Button>
                                    <Button color="info" size="sm" onClick={() => addPostCode(postCode.postCodeId, true)}><FaFolderPlus /></Button>
                                </div>
                            </div>
                        );
                })}
                </div>
            </div>
        </div>
    );
}