import React, { useState } from "react";
import { MdCompareArrows } from "react-icons/md";
import { Alert, Button, Input, Label } from "reactstrap";
import { useServerErrorState } from "../../contexts/ServerErrorContext";
import { useSecondPhaseFileState } from "../../contexts/SecondPhaseFileContext";
import { useDeliveryDateState } from "../../contexts/DeliveryDateContext";
import { BASEAPI } from "../../contexts/constants";
import { handleErrors } from "../Helpers";
import { format } from "date-fns";
import "./maps.css";

export default function MoveDocket({dockets, runs, runsUpdated}) {
    let [newRunId, setNewRunId] = useState();
    let [successMessage, setSuccessMessage] = useState();
    let [moving, setMoving] = useState(false);
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();
    let [deliveryDateState, dateDispatch] = useDeliveryDateState();

    function moveDocket() {
        setSuccessMessage(null);
        
        if (!newRunId || newRunId <= 0) {
            alert("Please select a run to move docket(s) to");
            return;
        }

        setMoving(true);

        var successMessages = [];

        // Promise.all( 
        //     dockets.map(docket => {                        
                fetch(BASEAPI + "/api/Run/MoveDocket", {
                    method: "POST",
                    body: JSON.stringify({
                        docketId: dockets[0].docketId,
                        newRunId: parseInt(newRunId)
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(handleErrors)
                .then(res => res.json())
                .then((result) => {
                    successMessages.push(dockets[0].memberNumber + ' ' + dockets[0].orderNumber + ' successfully moved to new run');
                    runsUpdated([result.oldRun, result.newRun]);
                    if (secondPhaseFileState.secondPhaseFile) {
                        dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.oldRun});
                        dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.newRun});
                    }
                })
                .catch(function(error){
                    console.log(error);
                    dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
                })
            //})
        // ).then(() => {
            setSuccessMessage(successMessages.join("\n"));    
            setMoving(false);
        // });
    }
        
    return ( <>
             <div>
                <Label>New Run</Label>
                <Input type="select" className="newRunSelect" onChange={(e) => setNewRunId(e.target.value)} value={newRunId}>
                    <option value="-1">Select New Run</option>
                    {runs.sort((a,b) => a.runNumber - b.runNumber).map((run, index) => {
                        var date = run.deliveryDateId ? deliveryDateState.deliveryDates.find(x => x.deliveryDateId  === run.deliveryDateId) : null;

                        return (
                            <option key={index} value={run.runId}>{run.runNumber + (date ? " [" + format(new Date(date.date), "dd/MM/yyyy")  +"]" : "")}</option>
                        )
                    })}
                </Input>
                </div>
                <div>
                    <Button color="primary" onClick={moveDocket} disabled={moving}><MdCompareArrows />Move Docket</Button>
                    {successMessage && 
                    <Alert color="success">{successMessage}</Alert>}
                </div>
        </>
    )

}