import React, { useState } from "react"
import { Form, Button, FormGroup, Label, Spinner, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useAppState } from "../contexts/AppContext";
import { useDepotState } from "../contexts/DepotContext";
import { BASEAPI } from "../contexts/constants";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import ImportedFileSecondPhaseRuns from "./ImportedFileSecondPhaseRuns";
import { useUserState } from "../contexts/UserContext";

export default function ImportDocketFileSecondPhase() {
    let { push } = useHistory();
    let [appState, appDispatch] = useAppState();
    let [depotState, depotDispatch] = useDepotState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [userState, userDispatch] = useUserState();

    let [deliveryDates, setDeliveryDates] = useState(null);
    let [importFileId, setImportFileId] = useState(null);
    let [file, setFile] = useState(null);

    let [postingFile, setPostingFile] = useState(false);

    var currentDepot = depotState.depots.find(d => d.depotId === parseInt(appState.selectedDepotId));

    function importFile(event) {
        setPostingFile(true);

        var fileInput = document.querySelector('#inputFile');

        const data = new FormData();
        data.append('uploadedFile', fileInput.files[0]);
        data.append('depotId', parseInt(appState.selectedDepotId));
        data.append('deliverySeasonId', parseInt(appState.selectedDeliverySeasonId))
        data.append('userName', userState.user)
        
        fetch(BASEAPI + '/api/ImportDockets/ImportSecondPhaseFile', {
            method: 'POST',
            body: data,
            headers: 
            {
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            setDeliveryDates(result.deliveryDates);
            setImportFileId(result.importFile.importFileId);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Uploading File", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setPostingFile(false);
        })

    }

    function fileChanged() {
        var fileInput = document.querySelector('#inputFile');
        //setFilename(fileInput.files.item(0).name);
        setFile(fileInput.files[0]);
    }

    return (
        <div>
            {!deliveryDates && 
            <div>
                <div>
                    <span className="returnBtn"><Button color="secondary" size="sm" onClick={() => push('/route')}>Return to List</Button></span>
                </div>
                <div>
                    <h2>Import Second Phase File for {currentDepot && currentDepot.name} Depot</h2>
                </div>
                <div className="text-info">
                    Excel File needs header and the following fields: <br/>
                    <pre>MEMBER#,ORDER#,STREET NAME,ADDR1,SUBURB,STATE,POSTCODE,DELV DATE,DELV RUN#,DELV TIME,SEQ#,TOTAL BOXES,TOTAL WEIGHT,SPECIAL INSTRS,DELIVERY AGENT COMMENT</pre>
                    System will import all dockets adding those to the specified Run Number for the Delivery Date.
                </div>
                <div>
                    <Form>
                        <FormGroup>
                            <Label for="inputFile">Docket File</Label>
                            <input type="file" name="file" id="inputFile" onChange={fileChanged}/>
                        </FormGroup>
                        {(deliveryDates == null || deliveryDates.length === 0) &&
                        <FormGroup>
                            <Button color="primary" onClick={importFile} disabled={postingFile}>Import</Button> {postingFile && <Spinner />}
                        </FormGroup>
                        }
                    </Form>
                </div>
            </div>
            }
            {deliveryDates && deliveryDates.length > 0 && 
                <div>
                    <Alert color="info">Successfully Imported File containing {deliveryDates.length} Dates</Alert>
                    <ImportedFileSecondPhaseRuns deliveryDates={deliveryDates} importFileId={importFileId} />
                </div>
            }
        </div>
    )
}