import React, { useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FaClipboard, FaFileExcel, FaClipboardList, FaTruck } from 'react-icons/fa';
import ImportedFileList from './ImportedFileList';
import DepotSelector from './DepotSelector';
import { useAppState } from '../contexts/AppContext';
import { handleErrors } from './Helpers';
import { BASEAPI } from '../contexts/constants';
import { useServerErrorState } from '../contexts/ServerErrorContext';
import { useUserState } from '../contexts/UserContext';

export default function RouteDockets() {
    let [appState, appDispatch] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [processing, setProcessing] = useState(false);

    function exportToExcel() {
        setProcessing(true);
        fetch(BASEAPI + "/api/depot/ExportToExcel/" + appState.selectedDepotId,
        {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "ExcelExport.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Generating Depot Excel Document", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    function exportDistances() {
        setProcessing(true);
        fetch(BASEAPI + "/api/depot/ExportToDistancesToExcel/" + appState.selectedDepotId)
        .then(handleErrors)
        .then(res => res.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "RunsDistancesExport.xlsx";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Generating Depot Run Distances Excel Document", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    return (
        <div>
            <DepotSelector />
            
            {appState.selectedDepotId > 0 && 
                <div>
                    <div>
                        <Button tag={Link} color="info" to="/import">Import File <FaClipboard /></Button>{' '}
                        <Button tag={Link} color="primary" to="/importSecond">Import 2nd Phase File <FaClipboardList /></Button>{' '}
                        <Button color="warning" onClick={exportToExcel}>Export All Files To Excel <FaFileExcel /></Button>{' '}
                        <Button color="secondary" onClick={exportDistances}>Export Distances <FaTruck /></Button>
                        {processing && <Spinner color="primary" />}
                    </div>
                    <ImportedFileList />
                </div>
            }
        </div>
    )
}