import React, { useEffect, useState } from "react";
import { BASEAPI } from "../contexts/constants";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Button, Spinner, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Label } from "reactstrap";
import DisplayRoutesMap from "./DisplayRoutesMap";
import { FaArrowUp, FaArrowDown, FaMap, FaPlus, FaRegCalendarAlt } from "react-icons/fa";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { MdCompareArrows } from "react-icons/md";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { useAppState } from "../contexts/AppContext";
import { format } from "date-fns";
import "./runs.css";
import { useSecondPhaseFileState } from "../contexts/SecondPhaseFileContext";
import { useUserState } from "../contexts/UserContext";
import RoutesMap from "./maps/RoutesMap";
import GoogleMap from "./maps/GoogleMap";

export default function RunDetails() {
    let { runId } = useParams();
    const location = useLocation();
    let importFileId = location.state.importFileId;

    let [errorState, dispatchServerError] = useServerErrorState();
    let [dateState, dateDispatch] = useDeliveryDateState();
    let [appState, appDispatch] = useAppState();
    let [userState, userDispatch] = useUserState();
    let [secondPhaseFileState, dispatchSecondPhaseFile] = useSecondPhaseFileState();

    let [run, setRun] = useState();
    let [runs, setRuns] = useState();
    let history = useHistory();
    let [showMap, setShowMap] = useState();
    let [optimiseRunning, setOptimiseRunning] = useState(false);
    let [fileRuns, setFileRuns] = useState();
    let [showMoveModal, setShowMoveModal] = useState(false);
    let [docketToMove, setDocketToMove] = useState();
    let [newRunId, setNewRunId] = useState();
    let [successMessage, setSuccessMessage] = useState();
    let [newDateId, setNewDateId] = useState();
    let [runDistance, setRunDistance] = useState();
    let [distancesUpdated, setDistancesUpdated] = useState(false);
    let [distancesUpdating, setDistancesUpdating] = useState(false);

    let delDates = dateState.deliveryDates.filter(x => x.depotId === parseInt(appState.selectedDepotId));

    var deliveryDate = run && run.deliveryDateId ? dateState.deliveryDates.find(x => x.deliveryDateId === run.deliveryDateId) : null;
    var formattedDate = deliveryDate ? format(new Date(deliveryDate.date), "dd/MM/yyyy") : "Unnassigned";
    if (deliveryDate && deliveryDate.isMorning != null) {
        formattedDate += (' ' + (deliveryDate.isMorning ? 'AM' : 'PM'));
    }   

    let prevAddressId = null;
    let altRow = false;

    function getDistance() {
        setDistancesUpdating(true);

        fetch(BASEAPI + "/api/Run/RunDistance/" + runId, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRunDistance(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Run Distance", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setDistancesUpdating(false);
        })
    }

    function recalcDistances() {
        fetch(BASEAPI + "/api/Run/RecalcDistances/" + runId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                setDistancesUpdated(true);
                getDistance();
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Getting Run Distance", errorMessage:"Server Error: " + error.message});
        })
    }

    function moveDocket() {
        setSuccessMessage(null);
        
        if (!newRunId || newRunId <= 0) {
            alert("Please select a run to move docket to");
            return;
        }

        fetch(BASEAPI + "/api/Run/MoveDocket", {
            method: "POST",
            body: JSON.stringify({
                docketId: docketToMove.docketId,
                newRunId: parseInt(newRunId)
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRun(result.oldRun);
            setRuns([result.oldRun]);
            setSuccessMessage(docketToMove.memberNumber + ' ' + docketToMove.orderNumber + ' successfully moved to new run');
            if (secondPhaseFileState.selectedImportFileId) {
                dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.oldRun});
                dispatchSecondPhaseFile({type: "UPDATE_RUN", run: result.newRun});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    useEffect(() => {
        fetch(BASEAPI + "/api/run/" + runId)
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRun(result);
            setRuns([result]);

            if (importFileId) {
                fetch(BASEAPI + "/api/run/RunsForFile/" + importFileId)
                .then(res => res.json())
                .then((fileResult) => {
                    if (secondPhaseFileState.selectedImportFileId && result.deliveryDateId)
                        fileResult = fileResult.reduce((redResult, value) => {
                            if (value.deliveryDateId === result.deliveryDateId) {
                                redResult.push(value);
                            }
                                
                            return redResult;
                        }, []);
                    setFileRuns(fileResult);
                })
            }

            getDistance();
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Run", errorMessage:"Server Error: " + error.message});
        });
    }, [])

    function toggleMap() {
        setShowMap(!showMap);
    }

    function moveDown(docketId) {
        fetch(BASEAPI + "/api/run/MoveDocketDown", {
            method: "POST",
            body: docketId,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                // var docket = run.dockets.find(x => x.docketId === docketId);
                // var currentSeq = docket.sequenceNumber;
                // var newSeq = currentSeq + 1;
                // var otherDocket = run.dockets.find(x => x.sequenceNumber == newSeq);
                // docket.sequenceNumber = newSeq;
                // otherDocket.sequenceNumber = currentSeq;

                // run.dockets = run.dockets.sort((a,b) => {
                //     if (a.sequenceNumber === b.sequenceNumber) {
                //         return 0;
                //     }
                //     if (a.sequenceNumber < b.sequenceNumber)
                //         return -1;
                //     else
                //         return 1;
                // })

                setRun({...result});
                if (secondPhaseFileState.selectedImportFileId)
                    dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    function moveUp(docketId) {
        fetch(BASEAPI + "/api/run/MoveDocketUp", {
            method: "POST",
            body: docketId,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            if (result) {
                // var docket = run.dockets.find(x => x.docketId === docketId);
                // var currentSeq = docket.sequenceNumber;
                // var newSeq = currentSeq - 1;
                // var otherDocket = run.dockets.find(x => x.sequenceNumber == newSeq);
                // docket.sequenceNumber = newSeq;
                // otherDocket.sequenceNumber = currentSeq;

                // run.dockets = run.dockets.sort((a,b) => {
                //     if (a.sequenceNumber === b.sequenceNumber) {
                //         return 0;
                //     }
                //     if (a.sequenceNumber < b.sequenceNumber)
                //         return -1;
                //     else
                //         return 1;
                // })

                setRun({...result});
                if (secondPhaseFileState.selectedImportFileId)
                    dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result});
            }
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Moving Docket", errorMessage:"Server Error: " + error.message});
        })
    }

    function organiseSequences() {
        setOptimiseRunning(true);
        fetch (BASEAPI + "/api/run/OptimiseSequence", {
            method: "POST",
            body: run.runId,
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRun(result);
            if (secondPhaseFileState.selectedImportFileId)
                dispatchSecondPhaseFile({type: 'UPDATE_RUN', run: result});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Sequencing Dockets", errorMessage:"Server Error: " + error.message});
        })
        .finally(function() {
            setOptimiseRunning(false);
        })
    }

    function assignDate() {
        setOptimiseRunning(true);

        var runIds = runs.reduce((result, value) => {
            result.push(value.runId);
            return result;
        }, []);

        fetch(BASEAPI + "/api/DeliveryDate/AssignRunToDate", {
            method: "POST",
            body: JSON.stringify({
                deliveryDateId: parseInt(newDateId),
                runId: run.runId
            }),
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            run.deliveryDateId = result.deliveryDateId;
            setRun(run);
            dateDispatch({type: 'ADD_RUNS_TO_DATE', deliveryDateId: result.deliveryDateId, runs: [run]});
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Assigning Delivery Date to Run", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setOptimiseRunning(false);
        })
    }

    function newRunDialog(docket) {
        setDocketToMove(docket);
        setShowMoveModal(true);
    }

    function closeModal() {
        setShowMoveModal(false);
    }

    return (
        <div>
            {!run && <Spinner />}
            {run && 
            <div>
                <div>
                    <h3>Detail for Run {run.runNumber} <span className="returnBtn"><Button color="secondary" size="sm" onClick={() => history.goBack()}>Return to List</Button></span></h3>
                </div>
                <div>
                    Delivery Date: {formattedDate}
                </div>
                <div>
                {delDates.length > 0 &&
                    <div>
                        <select value={newDateId} onChange={(e) => setNewDateId(e.target.value)}>
                            <option key="-1" value={null}>Please Select...</option>
                            {delDates.map((dd, index) => {
                                var formattedDate = format(new Date(dd.date), "dd/MM/yyyy");
                                if (dd.isMorning != null)
                                {
                                    formattedDate += ' ' + (dd.isMorning ? 'AM' : 'PM');
                                }
                                return (
                                    <option index={index} value={dd.deliveryDateId}>{formattedDate}</option>
                                )
                            })}
                        </select>
                        <Button color="success" onClick={assignDate}>Assign Run To Date <FaRegCalendarAlt /></Button>
                    </div>
                }
                {delDates.length == 0 &&
                <Alert color="warning">Please setup delivery dates in Depot Config to Assign Runs to Delivery Date</Alert>}
            </div>
                <div>
                    <div><Button color="info" onClick={toggleMap}><FaMap /> {showMap ? "Hide" : "Show"} Map</Button></div>
                    {showMap && 
                        // <DisplayRoutesMap runs={runs} depotId={run.depotId} />
                        <GoogleMap runs={runs} depotId={run.depotId} />}
                </div>
                    <div><Button color="warning" onClick={organiseSequences}><FaMap /> Auto Optimise Sequences</Button>{optimiseRunning && <Spinner color="info" />}
                </div>
                {runDistance &&
                <Alert color="info">
                    Total Distance Of Run: {runDistance / 1000} km
                    {userState.isDevUser &&
                        <div>
                            <div>&nbsp;</div>
                            <Button onClick={recalcDistances}>ReCalc Distances</Button>
                        </div>
                    }
                    {distancesUpdating && <Spinner />}
                    {distancesUpdated && <span>Distances Updated</span>}
                </Alert>
                }
                {run && <Container>
                    <Row className="docketHeader">
                        <Col>Sequence Number</Col>
                        <Col>Member Number</Col>
                        <Col>Order No</Col>
                        <Col>Address</Col>
                        <Col>Box Count</Col>
                        <Col>Order Weight</Col>
                        <Col></Col>
                    </Row>
                {run && run.dockets.map((docket, index) => {
                    var matchesPrevAddress = prevAddressId === docket.addressId;
                    prevAddressId = docket.addressId;
                    altRow = matchesPrevAddress ? altRow : !altRow;

                    var classes = (altRow ? 'docketRowAlt' : 'docketRow') + ' ' + (matchesPrevAddress ? 'sameAddressDivider' : '')

                    return <Row key={index} className={classes}>
                            <Col>{docket.sequenceNumber}</Col>
                            <Col>{docket.memberNumber}</Col>
                            <Col>{docket.orderNumber}</Col>
                            <Col>{docket.address.addressLine1 + ', ' + docket.address.city + ' ' + docket.address.state + ' ' + docket.address.postCode}</Col>
                            <Col>{docket.boxCount}</Col>
                            <Col>{docket.orderWeight}</Col>
                            <Col>
                                {!matchesPrevAddress &&
                                <>
                                    <Button onClick={() => newRunDialog(docket)}><MdCompareArrows /></Button>
                                    {index > 0 && <Button color="primary" onClick={() => moveUp(docket.docketId)}><FaArrowUp /></Button>}
                                    {index < run.dockets.length-1 && <Button color="primary" onClick={() => moveDown(docket.docketId)}><FaArrowDown /></Button>}
                                </>
                                }
                            </Col>
                        </Row>
                })}
                </Container>
                }
            </div>}

            <Modal isOpen={showMoveModal} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>Move Docket to New Run</ModalHeader>
                <ModalBody>
                    <div>
                        <Label>New Run</Label>
                        {fileRuns &&
                            <select onChange={(e) => setNewRunId(e.target.value)} value={newRunId}>
                                <option value="-1">Select New Run</option>
                                {fileRuns.map((run, index) => {
                                    var date = run.deliveryDateId ? dateState.deliveryDates.find(x => x.deliveryDateId  === run.deliveryDateId) : null;
                                    return (
                                        <option key={index} value={run.runId}>{run.runNumber + (date ? " [" + format(new Date(date.date), "dd/MM/yyyy")  +"]" : "")}</option>
                                    )
                                })}
                            </select>
                        }
                        {!fileRuns && <Spinner color="primary" />}
                    </div>
                    {successMessage && 
                            <Alert color="success">{successMessage}</Alert>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={moveDocket}><MdCompareArrows />Move Docket</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}