import React, { useState } from "react";

import "./maps.css";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import MoveDocket from "./MoveDocket";
import SequenceDocket from "./SequenceDocket";

export var InfoWindowTabEnum = {
    Info: 1,
    MoveRun: 2,
    ChangeSequence: 3
}
export default function MapInfoWindow({markerKey, label, infoMessage, closeWindow, dockets, runs, color, runsUpdated, sequenceUpdated}) {
    let [currentTab, setCurrentTab] = useState(InfoWindowTabEnum.Info);

    var title = dockets.length > 1 ? buildTitle(label, dockets) : label;
    var run = runs.find(x => x.runId === dockets[0].runId);
    var firstDocketIndex = run.dockets.findIndex(x => x.docketId === dockets[0].docketId);

    function buildTitle(label, dockets) {
        var runNo = label.split('-')[0];

        var minDocket = dockets.reduce(function (p, v) {
            return p.sequenceNumber < v.sequenceNumber ? p : v;
        })

        var maxDocket = dockets.reduce(function (p, v) {
            return p.sequenceNumber > v.sequenceNumber ? p : v;
        })

        return runNo + '-' + (minDocket.sequenceNumber ?? "#") + "..." + (maxDocket.sequenceNumber ?? "#");
    }

    function toggle(infoWindowTabType) {
        if (currentTab !== infoWindowTabType) {
            setCurrentTab(infoWindowTabType);
        }
    }

    function onCloseClick(event) {
        closeWindow(markerKey);
    }

    function onRunsUpdated(run) {
        runsUpdated(run);
    }

    function onSequenceUpdated(docketId, sequenceNumbers) {
        sequenceUpdated(docketId, sequenceNumbers);
    }

    return (<>
                 <div>
                        <div className="infoWindowTitle" style={{backgroundColor: color, color: "white"}}>{title}</div><div className="infoCloseBtn"><Button color="light" size="sm" onClick={onCloseClick}><FaTimes /></Button></div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={currentTab === InfoWindowTabEnum.Info ? 'active' : ''} onClick={() => toggle(InfoWindowTabEnum.Info)}>
                                    Docket
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={currentTab === InfoWindowTabEnum.MoveRun ? 'active' : ''} onClick={() => toggle(InfoWindowTabEnum.MoveRun)}>
                                    Change Run
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={currentTab === InfoWindowTabEnum.ChangeSequence ? 'active' : ''} onClick={() => toggle(InfoWindowTabEnum.ChangeSequence)}>
                                    Change Sequence
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={currentTab}>
                            <TabPane tabId={InfoWindowTabEnum.Info}>
                                {infoMessage}
                            </TabPane>
                            <TabPane tabId={InfoWindowTabEnum.MoveRun}>
                                <MoveDocket dockets={dockets} runs={runs} runsUpdated={onRunsUpdated} />
                            </TabPane>
                            <TabPane tabId={InfoWindowTabEnum.ChangeSequence}>
                                <SequenceDocket dockets={dockets} index={firstDocketIndex} noOfDocketsInRun={run.dockets.length} runsUpdated={onRunsUpdated} sequenceUpdated={onSequenceUpdated} />
                            </TabPane>
                        </TabContent>            
            </div>
    </>)
}