import React, { useEffect, useState } from "react";
import { useAppState } from "../contexts/AppContext";
import { BASEAPI } from '../contexts/constants';
import { handleErrors } from "./Helpers";
import { Container, Row, Col, Spinner, Label, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { format } from 'date-fns'
import "./taskList.css";
import { FaRecycle, FaRegListAlt } from "react-icons/fa";
import RouteProgress from "./RouteProgress";
import { MdRefresh } from "react-icons/md";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { taskStatusLabel } from "./Enums";
import { useUserState } from "../contexts/UserContext";

export default function TaskList() {
    let [appState, appDispatch] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();
    let [routeTasks, setRouteTasks] = useState();
    let [filterId, setFilterId] = useState(0);
    let [logTask, setLogTask] = useState();
    let [showLog, setShowLog] = useState(false);
    let [loading, setLoading] = useState(false);

    let filteredTasks = filterId > 0 ? routeTasks.filter(x => x.taskStatus === filterId) : routeTasks;

    function fetchTasks() {
        setLoading(true);
        fetch(BASEAPI + "/api/RouteTask/" + appState.selectedDeliverySeasonId, {
            headers: {
              Authorization: 'Bearer ' + userState.token
            }
          })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            setRouteTasks(result);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Tasks", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchTasks();
    }, [])

    function changeFilter(event) {
        var filterId = parseInt(event.target.value);
        setFilterId(filterId);
    }

    function requeue(taskId) {
        setLoading(true);

        fetch(BASEAPI + "/api/RouteTask/Reset/" + taskId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            var tasks = routeTasks.filter(x => x.taskId !== taskId);
            tasks = tasks.concat(result);
            setRouteTasks(tasks);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Retrieving Dockets for Import File", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setLoading(false);
        })
    }

    function viewLog(task) {
        setLogTask(task);
        setShowLog(true);
    }

    function logToggle() {
        setShowLog(!showLog);
    }

    function reloadTasks() {
        fetchTasks();
    }

    return (
        <div>
            <h1>Routing Tasks</h1>

            <div>
                <div onChange={changeFilter}>
                    <Label><input type="radio" value="0" name="taskFilter" defaultChecked disabled={loading}/>All </Label>
                    <Label><input type="radio" value="1" name="taskFilter" disabled={loading}/>Queued</Label>
                    <Label><input type="radio" value="2" name="taskFilter" disabled={loading}/>Running</Label>
                    <Label><input type="radio" value="3" name="taskFilter" disabled={loading}/>Completed</Label>
                    <Label><input type="radio" value="4" name="taskFilter" disabled={loading}/>Failed</Label>
                </div>
                <Button color="info" onClick={reloadTasks}><MdRefresh /></Button>{loading && <Spinner color="success" />}
            </div>

            <Container className="taskList">
                <Row className="taskHeader">
                    <Col>Task Id</Col>
                    <Col>Queue Time</Col>
                    <Col>Start Time</Col>
                    <Col>End Time</Col>
                    <Col>Filename</Col>
                    <Col>Status</Col>
                    <Col>&nbsp;</Col>
                </Row>

            {filteredTasks && filteredTasks.map((task, index) => {
                var queueTime = format(new Date(task.queueTime), "dd/MM/yyyy hh:mm:ss")
                var startTime = task.startTime ? format(new Date(task.startTime), "dd/MM/yyyy hh:mm:ss") : ''
                var endTime = task.endTime ? format(new Date(task.endTime), "dd/MM/yyyy hh:mm:ss") : ''
                return (
                    <Row>
                        <Col className="taskId">{task.taskId}</Col>
                        <Col>{queueTime}</Col>
                        <Col>{startTime}</Col>
                        <Col>{endTime}</Col>
                        <Col>{task.importFile ? task.importFile.fileName : ''}</Col>
                        <Col>{taskStatusLabel(task.taskStatus)}</Col>
                        <Col>
                            {task.taskStatus === 4 && <Button color="warning" size="sm" onClick={() => requeue(task.taskId)}><FaRecycle /></Button>}
                            <Button color="primary" size="sm" onClick={() => viewLog(task)}><FaRegListAlt /></Button>
                        </Col>
                    </Row>
                )
            })}
            </Container>

            <Modal isOpen={showLog} size="xl">
                <ModalHeader toggle={logToggle}>Routing Log for Task {logTask ? logTask.taskId : ''} for FileName: {logTask && logTask.importFile ? logTask.importFile.fileName : ''}</ModalHeader>
                <ModalBody>
                    {logTask && logTask.logEntries &&
                        <RouteProgress progressEvents={logTask.logEntries} />
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}