import React, { useState, useEffect } from "react";
import { Button, Progress, Spinner } from "reactstrap";
import { MdEdit, MdCancel } from "react-icons/md";
import "./runs.css";
import { useHistory } from "react-router-dom";
import { linecolors, BASEAPI, triple8Color } from "../contexts/constants";
import { pad, handleErrors } from "./Helpers";
import { FaPencilAlt } from "react-icons/fa";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { format } from "date-fns";
import { useUserState } from "../contexts/UserContext";

export default function RunTile(params) {
    let [run, setRun] = useState();
    let [truckType, setTruckType] = useState();
    let [runIndex, setRunIndex] = useState(params.runIndex);
    let [importFileId, setImportFileId] = useState(params.importFileId);
    let [editingRunNumber, setEditingRunNumber] = useState(false);
    let [newRunNumber, setNewRunNumber] = useState();
    let history = useHistory();
    let [errorState, dispatchServerError] = useServerErrorState();
    let [updating, setUpdating] = useState(false);
    let [dateState, dateDispatch] = useDeliveryDateState();
    let [userState, dispatchUser] = useUserState();

    var deliveryDate = run && run.deliveryDateId ? dateState.deliveryDates.find(x => x.deliveryDateId === run.deliveryDateId) : null;
    var formattedDate = deliveryDate ? format(new Date(deliveryDate.date), "dd/MM/yyyy") : "Unnassigned";
    if (deliveryDate && deliveryDate.isMorning != null) {
        formattedDate += (' ' + (deliveryDate.isMorning ? 'AM' : 'PM'));
    }   

    var lineColour = linecolors[runIndex%20];

    var boxCount = 0;
    var totalWeight = 0;

    if (run) {
        if (run.runNumber === 888) {
            lineColour = triple8Color;
        }


        for(var i = 0; i < run.dockets.length; i++) {
            boxCount += run.dockets[i].boxCount;
            totalWeight += run.dockets[i].orderWeight;
        }
    }

    var boxPerc = run && truckType ? boxCount / truckType.maxBoxCount * 100 : 0;
    var weightPerc = run && truckType ? totalWeight / truckType.maxWeight * 100 : 0;

    var boxProgCol = progressColour(boxPerc);
    var weightProgCol = progressColour(weightPerc);

    function progressColour(perc) {
        if (perc > 100)
            return "danger";

        if (perc > 90)
            return "warning";
        
        return "success";
    }

    function editRunNumber() {
        setEditingRunNumber(true);
    }

    function cancelEdit() {
        setEditingRunNumber(false);
    }

    function saveNewRunNumber() {
        setUpdating(true);

        fetch(BASEAPI + "/api/run/UpdateRunNumber", {
            method: "POST",
            body: JSON.stringify({
                runId: parseInt(run.runId),
                runNumber: parseInt(newRunNumber),
                importFileId: parseInt(importFileId)
                }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userState.token
            }
        })
        .then(handleErrors)
        .then(res => res.json())
        .then((result) => {
            run.runNumber = result.runNumber;
            setRun(run);
            setEditingRunNumber(false);
        })
        .catch(function(error){
            console.log(error);
            dispatchServerError({type: "SET_ERROR",errorTitle:"Error Updating Run Number", errorMessage:"Server Error: " + error.message});
        })
        .finally(() => {
            setUpdating(false);
        })
    }

    useEffect(() => {
        setRun(params.run);
        setTruckType(params.truckType);
        setRunIndex(params.runIndex);
        setImportFileId(params.importFileId);
        setNewRunNumber(params.run.runNumber);
    }, [params.run, params.truckType, params.runIndex, params.importFileId])

    return (
        <div>
        {run && 
            <div className="runTile" style={{backgroundColor: lineColour}}>
                {!editingRunNumber && <div className="runNumber" onClick={editRunNumber}>{pad(run.runNumber, 3)}<FaPencilAlt size={12}  /></div>}
                {editingRunNumber && <div><input id="runNumber" type="number" value={newRunNumber} onChange={(e) => setNewRunNumber(e.target.value)} /><Button className="runNumberEditBtn" size="sm" color="primary" onClick={saveNewRunNumber}>Save</Button><Button size="sm" color="secondary" onClick={cancelEdit}><MdCancel /></Button></div>}
                {updating && <Spinner />}
                <div>
                    <Button color="secondary" size="sm" onClick={() => {
                        history.push({
                        pathname: "/run/" + run.runId,
                        state: { importFileId: importFileId }
                        });
                    }}>Run Details <MdEdit></MdEdit></Button>
                </div>
                <div>
                    <div>DeliveryDate: {formattedDate} </div>
                    <div>Dockets: {run.dockets.length}</div>
                    <div>Boxes: <Progress color={boxProgCol} value={boxPerc}>{boxCount} / {truckType ? truckType.maxBoxCount : 0}</Progress></div>
                    <div>Order Weight: <Progress color={weightProgCol} value={weightPerc}>{Math.round(totalWeight)} / {truckType ? truckType.maxWeight : 0}</Progress></div>
                </div>
            </div>
        }
        </div>
    )
}