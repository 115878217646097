import React from "react";
import { Card, CardTitle, InputGroup, Button, CardBody } from "reactstrap";
import { BASEAPI } from "../contexts/constants";
import { useAppState } from "../contexts/AppContext";
import { Link } from "react-router-dom";

export default function ImportDocketComplete(params) {
    let [appState, appDispatch] = useAppState();
    let dockets = params.dockets;
    let docketCount = dockets.length;
    let fileName = params.fileName;
    let importFileId = params.importFileId;

    return (
        <div>
            <Card body inverse color="primary" className="text-center" style={{ width: '18rem' }}>
                <CardTitle>Import Completed Succesfully</CardTitle>
                <CardBody>{fileName} imported {docketCount} dockets Succesfully.  Dockets are ready for routing into runs.</CardBody>
                <Button color="Primary" tag={Link} to={"/engine/" + importFileId}>Go To Route</Button>
            </Card>
        </div>
    )
}