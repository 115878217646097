import React from "react";
import './maps.css';
import MapInfoWindow from "./MapInfoWindow";

export default function Marker({color, label, infoMessage, show, dockets, runs, onWindowClose}) {
    return <div className="marker">
        <svg>
            <g>
                <path d="M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z" 
                    stroke="#FFFFFF" stroke-width="2" fill={color} transform="scale(2,2)"></path>
                <text x="14" y="22" fill="white" fontSize="9px">{label}</text>
            </g>
        </svg>
        {/* {show && <MapInfoWindow label={label} infoMessage={infoMessage} dockets={dockets} runs={runs} closeWindow={onWindowClose}/>} */}
    </div>
}