import React, { useState } from "react"
import { MdDateRange } from "react-icons/md"
import './AddDeliveryDateTile.css'
import { Button, Label } from "reactstrap"
import { useDepotState } from "../contexts/DepotContext";
import { useAppState } from "../contexts/AppContext";
import { BASEAPI } from "../contexts/constants";
import { useDeliveryDateState } from "../contexts/DeliveryDateContext";
import { handleErrors } from "./Helpers";
import { useServerErrorState } from "../contexts/ServerErrorContext";
import { useUserState } from "../contexts/UserContext";

export default function AddDeliveryDateTile({depotId}) {
    let [dateToAdd, setDateToAdd] = useState();
    let [twoSessions, setTwoSessions] = useState();
    let [depotState, dispatchDepot] = useDepotState();
    let [deliveryDateState, dateDispatch] = useDeliveryDateState();
    let [appState, dispatchApp] = useAppState();
    let [userState, dispatchUser] = useUserState();
    let [serverErrorState, dispatchServerError] = useServerErrorState();

    function handleClick() {
        if (twoSessions) {
            fetch(BASEAPI + "/api/Depot/AddDeliveryDate", {
                method: "POST",
                body: JSON.stringify({
                    depotId: depotId,
                    deliverySeasonId: parseInt(appState.selectedDeliverySeasonId),
                    date: dateToAdd,
                    isMorning: true,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                dispatchDepot({type: 'ADD_DELIVERYDATE', depotId: depotId, deliveryDate: result.deliveryDate});
                dateDispatch({type: 'ADD_DATE', deliveryDate: result.deliveryDate});
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Morning Delivery Session", errorMessage:"Server Error: " + error.message});
            })

            fetch(BASEAPI + "/api/Depot/AddDeliveryDate", {
                method: "POST",
                body: JSON.stringify({
                    depotId: depotId,
                    deliverySeasonId: parseInt(appState.selectedDeliverySeasonId),
                    date: dateToAdd,
                    isMorning: false,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                dispatchDepot({type: 'ADD_DELIVERYDATE', depotId: depotId, deliveryDate: result.deliveryDate})
                dateDispatch({type: 'ADD_DATE', deliveryDate: result.deliveryDate});
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Afternoon Delivery Session", errorMessage:"Server Error: " + error.message});
            })
        }
        else {
            fetch(BASEAPI + "/api/Depot/AddDeliveryDate", {
                method: "POST",
                body: JSON.stringify({
                    depotId: depotId,
                    deliverySeasonId: parseInt(appState.selectedDeliverySeasonId),
                    date: dateToAdd,
                    isMorning: null,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + userState.token
                }
            })
            .then(handleErrors)
            .then(res => res.json())
            .then((result) => {
                dispatchDepot({type: 'ADD_DELIVERYDATE', depotId: depotId, deliveryDate: result.deliveryDate})
                dateDispatch({type: 'ADD_DATE', deliveryDate: result.deliveryDate});
            })
            .catch(function(error){
                console.log(error);
                dispatchServerError({type: "SET_ERROR",errorTitle:"Error Adding Delivery Date", errorMessage:"Server Error: " + error.message});
            })
        }
    }

    return (
        <div className="addDate">
            <div><Label>Delivery Date:<input type="date" value={dateToAdd} onChange={e => setDateToAdd(e.target.value)} /></Label></div>
            <div><Label>Two Session Day: <input type="checkbox" checked={twoSessions} onChange={e => setTwoSessions(e.target.value)} /></Label></div>
            <div><Button className="btn addDateBtn" color="danger" onClick={handleClick}>Add Delivery Date <MdDateRange /></Button></div>
        </div>
    )
}